import axios from "axios";
import { DOMAIN, PORT, SCHEME } from "../../Constants/Constants";

class FormService {

    addformcontactus(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addformcontactus`, dat);
    };

    addformcommon(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addformcommon`, dat);
    };

}

export default new FormService();