import React, { useEffect, useState } from "react";

import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "./img/biglogoservice.png";
import roundtxtpng from "./img/roundtxt.png";
import element1png from "./img/element1.png";
import element2png from "./img/element2.png";

import uparrowelement from "./img/uparrowelement.png";

import mannairoundtxt from "./img/Mannai Circle 001@72x.png";
import CookieBanner from "./img/COOKIES.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "./css/animate.min.css";
import "./css/ionicons.min.css";
import "./css/style.css";
import "./css/Cookiepolicy.css";
import "./js/timeline";
import "./js/script";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ReactGA from "react-ga4";
import ComponentServices from "../Components/ComponentServices";

const CookiePolicy = () => {
  const [metatitle, setmetatitle] = useState("Cookie Policy | Mannai Travel");

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.cookiepolicy && ev?.data?.cookiepolicy?.title ? ev?.data?.cookiepolicy?.title : "Cookie Policy | Mannai Travel");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.cookiepolicy && ev?.data?.cookiepolicy?.title ? ev?.data?.cookiepolicy?.title : "Cookie Policy | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Cookie Policy | Mannai Travel" });
        console.error("Error fetching SEO data: ", err);
      });
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div className="main-body theme-orange dark-horizontal cookiepolicy overflow-hidden">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title={metatitle} />
          <Header />

          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={CookieBanner}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>

              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>

              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    COOKIE POLICY
                  </h3>
                  <div className="leftarrowanimationelement">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            <div className="col-12 col-md-2 col-lg-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5"></div>
            </div>
            <div className="col-12 col-md-7 col-lg-5  justify-content-center align-items-center padmob">
              <div className="mx-4 testing">
                <h2
                  className="text-white termpart"
                  style={{ textAlign: "left" }}
                >
                  Mannai Travel
                </h2>
                <br />
                <h2
                  className="text-white  termpart"
                  style={{ textAlign: "left" }}
                >
                  Cookie Policy
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-5  col-lg-4 ">
              <h3
                className="text-center text-white mt-5  termupdate"
                style={{ textAlign: "left" }}
              >
                UPDATED DATE : 10-10-23
              </h3>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="row mobborderbottom">
              <div className="col-12 col-lg-12  wow fadeInRight ">
                <div className="cdecontentcd py-5">
                  <h3 className="text-white  ">Effective Date: 10-10-23</h3>
                  <p className="text-white ">
                    Welcome to Mannai Travel Qatar. This Cookie Policy provides
                    information about how we use cookies and similar
                    technologies on our website. By using our website, you
                    consent to the use of cookies in accordance with this
                    policy.
                  </p>
                  <ol>
                    <li className="text-white abouttext mt-2">
                      What are Cookies?
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white ">
                        Cookies are small text files that are placed on your
                        device when you visit a website. They help improve your
                        browsing experience by remembering your preferences,
                        storing your session information, and providing
                        personalized content.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">
                      Types of Cookies We Use:
                    </li>
                    <ol>
                      <li className="text-white ">
                        Essential Cookies: These cookies are necessary for the
                        proper functioning of our website and enable core
                        features such as security, page navigation, and access
                        to secure areas.
                      </li>
                      <li className="text-white ">
                        Performance Cookies: These cookies collect information
                        about how you use our website, such as which pages you
                        visit and any errors you may encounter. These cookies
                        help us improve the website's performance.
                      </li>
                      <li className="text-white ">
                        Functional Cookies: These cookies allow our website to
                        remember choices you make (such as language or region)
                        and provide enhanced features.
                      </li>
                      <li className="text-white ">
                        Marketing Cookies: Marketing cookies are used to track
                        user interactions and deliver targeted advertisements
                        based on your interests and preferences.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      How We Use Cookies:
                    </li>
                    <span className="my-2">
                      We use cookies for various purposes, including, but not
                      limited to:
                    </span>
                    <ul className="list-unstyled">
                      <li className="text-white  ">
                        - Analyzing website traffic and user behavior.
                      </li>
                      <li className="text-white ">
                        - Personalizing your experience by remembering your
                        preferences.
                      </li>
                      <li>
                        - Managing and tracking your bookings and reservations.
                      </li>
                      <li>
                        - Measuring the effectiveness of our marketing
                        campaigns.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">
                      Managing Your Cookie Preferences:
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        You can manage your cookie preferences through your
                        browser settings. Most browsers allow you to control and
                        block cookies. However, please note that blocking
                        essential cookies may affect your ability to use certain
                        features of our website.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">
                      Third-Party Cookies:
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        Our website may use third-party cookies, including those
                        from service providers and advertisers. These third
                        parties may collect information about your online
                        activities over time and across different websites.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">
                      Updates to Our Cookie Policy:
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        We may update this Cookie Policy to reflect changes in
                        our practices or services. Any updates will be posted on
                        our website with the effective date.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">Contact Us:</li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        If you have any questions or concerns regarding our use
                        of cookies or this Cookie Policy, please contact us at
                        <a
                          className="text-decoration-none"
                          href="mailto: cookiepolicy@mannai.com"
                        >
                          {" "}
                          cookiepolicy@mannai.com.{" "}
                        </a>
                      </li>
                      <li className="text-white my-2">
                        Thank you for choosing Mannai Travel Qatar. We hope this
                        policy provides clarity about how we use cookies to
                        enhance your online experience with us.
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
