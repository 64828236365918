import axios from "axios";
import { DOMAIN, PORT, SCHEME } from "../../../Constants/Constants";

class BlogNewsServices {

    getReadBlog(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/readblog/${id}`)
    }

    getFeaturedBlog() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getfeatured`);
    };

    getBlogById(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getblog/${id}`)
    };

    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };

    getNews() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getnews`);
    };

    getNewsById(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getnews/${id}`);
    };

    getReadNews(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/readnews/${id}`)
    };

}

export default new BlogNewsServices();