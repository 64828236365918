import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoute from "./Routes/MainRoute";
import "./App.css";
import "./index.css";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-6PMR3H92NT"; //GA TrackingID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <Router>
      <MainRoute />
    </Router>
  );
};

export default App;
