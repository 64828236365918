import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/WhatWeDoPage/Home";
import Technologies from "../Pages/TechnologiesPage/Technologies";
import Whoweare from "../Pages/WhoWeArePage/Whoweare";
import Leisuretravel from "../Pages/LeisureTravelPage/Leisuretravel";
import Businesstravel from "../Pages/BussinessTravelPage/Businesstravel";
import Blogandnews from "../Pages/BlogsAndNewsPage/Blogsandnews";
import VisitThailand from "../Pages/BlogsAndNewsPage/Blogs";
import News from "../Pages/BlogsAndNewsPage/News";
import ConnectUs from "../Pages/ConnectUs";
import TermsandConditions from "../Pages/Terms&Conditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";
import SpaceTravel from "../Pages/SpaceTravelPage/SpaceTravel";
import MannaiHoliday from "../Pages/MannaiHolidayPage/MannaiHoliday";
import GlobalPartner from "../Pages/GlobalPartnersPage/GlobalPartner";
import SpaceCargo from "../Pages/SpaceTravelPage/SpaceCargo";
import Services from "../Pages/WhatWeDoPage/Services";
import NotFoundPage from "../Components/NotFoundPage";

const PRoute = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/Services" element={<Services />} />
        <Route path="/Technologies" element={<Technologies />} />
        <Route path="/Whoweare" element={<Whoweare />} />
        <Route path="/Leisuretravel" element={<Leisuretravel />} />
        <Route path="/Businesstravel" element={<Businesstravel />} />
        <Route path="/Blogsandnews" element={<Blogandnews />} />
        <Route path="/Blogsandnews/Blogs/:ID" element={<VisitThailand />} />
        <Route path="/Blogsandnews/News/:ID" element={<News />} />
        <Route path="/connectus" element={<ConnectUs />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/cookiepolicy" element={<CookiePolicy />} />
        {/* <Route path="/Career/CareerDetails/:ID" element={<CareerDetails />} />
        <Route path="/Career" element={<Career />} /> */}
        <Route path="/SpaceTravel" element={<SpaceTravel />} />
        <Route path="/SpaceCargo" element={<SpaceCargo />} />
        {/* <Route path="/SpaceLuftansa" element={<SpaceTravel />}/> */}
        <Route path="/MannaiHoliday" element={<MannaiHoliday />} />
        <Route path="/GlobalPartner" element={<GlobalPartner />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default PRoute;
