import React, { createContext, useEffect, useState } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import WOW from "wow.js";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";

import "../js/script";
import Partners from "../../Components/Partners";
import Section1 from "./Section1";
import Section3 from "./Section3";
import Section4 from "./Section4";
import SectionShape from "./SectionShape";
import BlogNewsServices from "./BlogsandNewsServices/BlogNewsServices";
import ReactGA from "react-ga4";
import ComponentServices from "../../Components/ComponentServices";

export const blogContext = createContext();

const Blogandnews = () => {
  const [metatitle, setmetatitle] = useState("Blogs & News | Mannai Travel")
  const [featureddata, setfeatureddata] = useState('');
  const [featuredimg, setfeaturedimg] = useState('');
  const [blogimages, setblogimages] = useState([]);
  const [newsdata, setnewsdata] = useState([]);
  const [newsimages, setnewsimages] = useState([]);

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.blogsandnews && ev?.data?.blogsandnews?.title ? ev?.data?.blogsandnews?.title : "Blogs & News | Mannai Travel")
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.blogsandnews && ev?.data?.blogsandnews?.title ? ev?.data?.blogsandnews?.title : "Blogs & News | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blogs & News | Mannai Travel" });
        console.error("Error fetching SEO data: ", err)
      });
  }, [])

  useEffect(() => {
    BlogNewsServices
      .getFeaturedBlog()
      .then(async (e) => {
        setfeatureddata(e.data);
        const [tablename, date, filename] = e.data?.imagePath.split('/');

        BlogNewsServices
          .getImage(tablename, date, filename)
          .then((img) => setfeaturedimg(URL.createObjectURL(img.data)))
          .catch((err) => console.log("error fetching featured image", err));

        const images = e.data?.blogs.map(async (ev) => {
          const [tablename, date, filename] = ev?.imagePath.split('/');

          const res = await BlogNewsServices.getImage(tablename, date, filename);
          return URL.createObjectURL(res.data);
        });

        const fin = await Promise.all(images);
        setblogimages(fin);
      })
      .catch((err) => console.log("error fetching featured data", err));

    BlogNewsServices
      .getNews()
      .then(async (eve) => {
        setnewsdata(eve?.data);

        const images = eve?.data?.map(async (enc) => {
          const [tablename, date, filename] = enc?.imagePath.split('/');

          const res = await BlogNewsServices.getImage(tablename, date, filename);
          return URL.createObjectURL(res.data);
        });

        const fin = await Promise.all(images);
        setnewsimages(fin);
      })
      .catch((err) => console.log("error fetching news data", err));
  }, []);


  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <>
      <ReactTitle title={metatitle} />
      <Header />
      <Section1 />
      <div className="carblunbgcd" id="Blogsandnews">
        <blogContext.Provider value={[featureddata, featuredimg]}>
          <SectionShape />
        </blogContext.Provider>

        <blogContext.Provider value={[featureddata, blogimages]}>
          <Section3 />
        </blogContext.Provider>
      </div>
      <blogContext.Provider value={[newsdata, newsimages]}>
        <Section4 />
      </blogContext.Provider>
      <Partners />
      <Footer />
    </>
  );
};

export default Blogandnews;
