import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../js/script";

import demobg from "../img/Window-airplane.jpg";
import ReactGA from "react-ga4";
import FormService from "../FormServices/FormService";

const GSAForm = ({ invoker }) => {
  const location = useLocation();
  const modRef = useRef(null);
  const [modContent, setmodContent] = useState("");
  useEffect(() => { }, []);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    WorkEmail: "",
    PhoneNumber: "",
    Country: "",
    POBOX: "",
    Designation: "",
    HowDidYouHearAboutUs: "",
    NumberOfUsersRequired: "",
    check: false,
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegexPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
    const emailRegex = new RegExp(emailRegexPattern);

    ReactGA.event({
      category: "Form Submission",
      action: "Click",
      label: invoker,
      value: invoker,
    });

    if (emailRegex.test(formData?.WorkEmail)) {
      const dat = {
        formname: invoker,
        fname: formData?.FirstName,
        lname: formData?.LastName,
        companyname: formData?.CompanyName,
        workemail: formData?.WorkEmail,
        phno: formData?.PhoneNumber,
        country: formData?.Country,
        postalcode: formData?.POBOX,
        designation: formData?.Designation,
        howdidyouhear: formData?.HowDidYouHearAboutUs,
        noofusersrequired: formData?.NumberOfUsersRequired
      };

      setmodContent("Thank you for contacting Mannai Travels. You'll soon receive an email from us.");
      modRef.current.click();

      FormService
        .addformcommon(dat)
        .then(setFormData({ FirstName: "", LastName: "", CompanyName: "", WorkEmail: "", PhoneNumber: "", Country: "", POBOX: "", Designation: "", HowDidYouHearAboutUs: "", NumberOfUsersRequired: "", check: false }))
        .catch((err) => console.error("Error adding FormCommon: ", err));

    } else {
      setmodContent("Please Enter a valid email address");
      modRef.current.click();
    }
  };

  return (
    <div className="section p0 ">

      {/*DO NOT REMOVE  --Customized Model Hidden Button */}
      <button hidden type="button" class="btn btn-primary"
        ref={modRef}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"></button>

      {/*DO NOT REMOVE --Customized Model Based Alert */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body d-flex justify-content-between align-items-center">
              <div>{modContent}</div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
      </div>

      <div className="section-item ">
        <div className="container-fluid wow fadeInUp " data-wow-delay=".2s">
          <div className="formbg formpart row justify-content-center align-items-center">
            <div className="col-lg-7 p-5 ">
              <h4 className="wow fadeInUp">
                WANT TO KNOW MORE ABOUT OUR GSA CARRRIER’S
              </h4>
              <p
                className="normaltxt wow fadeInUp"
                data-wow-delay="1s"
                data-wow-duration="1.2s"
              >
                Fill the form below to get connected to our GSA sales team
              </p>

              <form onSubmit={handleSubmit} className="text-left wow fadeInUp">
                <div className="row justify-content-center gap-2">
                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="First Name*"
                      name="FirstName"
                      value={formData.FirstName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Last Name*"
                      name="LastName"
                      value={formData.LastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 gap-2">
                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Company Name*"
                      name="CompanyName"
                      value={formData.CompanyName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Work Email*"
                      name="WorkEmail"
                      value={formData.WorkEmail}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 gap-2">
                  <div className="col-md-5  ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Phone Number*"
                      name="PhoneNumber"
                      value={formData.PhoneNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Country(India)"
                      name="Country"
                      value={formData.Country}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 gap-2">
                  <div className="col-md-5  ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="P.O Box / Postal Code"
                      name="POBOX"
                      value={formData.POBOX}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-5  ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Designation"
                      name="Designation"
                      value={formData.Designation}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 gap-2">
                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="How did you hear about us ?"
                      name="HowDidYouHearAboutUs"
                      value={formData.HowDidYouHearAboutUs}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-5 ">
                    <input
                      type="text"
                      className="form-control form-control-custom"
                      placeholder="Number of User’s Required "
                      name="NumberOfUsersRequired"
                      value={formData.NumberOfUsersRequired}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3 tabcheck">
                  <div className="checkbox wow fadeInUp col-1 p-0 d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="largerCheckbox "
                      name="check"
                      onChange={handleChange}
                      checked={formData.check}
                      required
                    />
                    <span className="checkmark"></span>{" "}
                  </div>
                  <p className="formtext wow fadeInUp col-11">
                    {" "}
                    By filling and submitting this form you understand and agree
                    that the use of Mannai Travel web site is subject to the
                    Mannaitravels.com <span>Terms &amp; Conditions.</span>{" "}
                    Additional details regarding Finanzz collection and use of
                    your personal information, including information about
                    access, retention, rectification, deletion, security,
                    cross-border transfers and other topics, is available in the
                    Mannaitravels.com <span>Privacy Policy.</span>
                  </p>
                </div>
                <button
                  className="btn btn-primary my-2  formbtn wow fadeInLeft"
                  data-wow-delay="1s"
                  data-wow-duration="1.2s"
                  type="submit"
                >
                  SUBMIT <i className="icon ion-ios-arrow-thin-right"></i>
                </button>{" "}
              </form>
            </div>
            <div className=" col-md-5 choose-future">
              <img src={demobg} alt="" className="wow fadeIn responsive " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSAForm;
