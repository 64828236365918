import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

import WOW from "wow.js";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/globarpartner.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Responsiveall.css";
import "../css/leisuretravel.css";
import "../js/script";
import element2png from "../img/woman-safety-equipment-work.jpg";
import pluselement from "../img/pluselement.png";
// import burjalaran from "../img/burj-"
import travelsuitcase from "../img/business-people-commuter-walking-city-life-concept.jpg";
import burjalarab from "../img/burj-al-arab-1142404_1280.jpg";
import mice from "../img/Mice.jpg";
// import "./styles.css"

const SpaceLufthansa = () => {
  const location = useLocation();
  // useEffect(() => {
  //   $(document).ready(function () {
  //     $(".animsition").animsition({
  //       inClass: "fade-in",
  //       outClass: "fade-out",
  //       inDuration: 1500,
  //       outDuration: 800,
  //       linkElement: ".animsition-link",
  //       // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
  //       // loading: true,
  //       // loadingParentElement: "body", //animsition wrapper element
  //       loadingClass: "preloader",
  //       loadingInner:
  //         // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
  //         '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
  //       timeout: true,
  //       timeoutCountdown: 1000,
  //       onLoadEvent: true,
  //       browser: ["animation-duration", "-webkit-animation-duration"],
  //       // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
  //       // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
  //       overlay: false,
  //       overlayClass: "animsition-overlay-slide",
  //       overlayParentElement: "body",
  //       transition: function (url) {
  //         window.location.href = url;
  //       },
  //     });
  //   });

  //   const htmlTag = document.querySelector("html");
  //   if (htmlTag.classList.contains("body-menu-opened")) {
  //     htmlTag.classList.remove("body-menu-opened");
  //     htmlTag.classList.add("body-menu-close");
  //   }

  //   // Initialize or use the scripts here
  //   // For example, you can initialize a WOW.js animation
  //   // $(".a-counter").countdown(() => {});
  //   new WOW().init();
  // }, []);
  // useEffect(() => {
  //   console.log(location);
  //   const ppnav = document.getElementById("pp-nav");
  //   if (ppnav) {
  //     ppnav.classList.remove("d-block");
  //     ppnav.classList.add("d-none");
  //   }
  //   console.log("hi");

  //   if (ppnav) {
  //     ppnav.remove(); // Remove the ppnav element from the DOM
  //   }
  // }, []);

  return (
    <div>
      <div className="blunbg1 px-md-5 px-0">
        <p className="fw-semibold text-light fs-5 text-justify  p-5 text-align-justify wow fadeInUp">
          With a network of 561 offices in 105 countries, the Lufthansa City
          Center (LCC) international travel agency franchise cooperation is one
          of the largest of its kind in the world. The cooperation of
          self-managed, medium-sized travel agencies with an airline company
          under an international brand name is unique worldwide. Our Space
          Travel division is among Qatar's few chosen representatives of the
          Lufthansa City Centre (LCC). Branded as LLC Space Travel, we offer our
          clients professional, responsive, and personalized service and provide
          a full-service experience to corporate clients and individual
          travelers.
        </p>
        <div className="text-center fw-bold text-dark mt-3">
          <h3
            className="pb-4 mx-5 text-blue wow slideInLeft"
            style={{ borderBottom: "2px dashed #17355d" }}
          >
            LUFTHANSA CITY CENTER SPACE TRAVEL SERVICE OFFERINGS IN QATAR
          </h3>
        </div>
      </div>
      <div className="p-3">
        <div className="row g-0 overflow-hidden">
          <div class="lufcontainer wow fadeInLeft">
            <div class="lufcontent">
              <span>
                <div class="lufcontent-overlay"></div>
                <div className=" w-100 lufcontent-overlay2">
                  <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <div className="d-flex align-items-center mb-3">
                      <img src={pluselement} />
                      <div className="plustitle-ml-60">
                        <h1 className="fs-1 fw-bold text-white">BUSINESS</h1>
                        <h1 className="fs-1 fw-bold text-white">TRAVEL</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="lufcontent-image" src={travelsuitcase} alt="" />
                <div class="lufcontent-details luffadeIn-bottom">
                  {/* <h3 class="lufcontent-title">This is a title</h3> */}
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    At Lufthansa City Center Space Travel, we can help you plan
                    and execute meetings and conferences of all sizes. We pride
                    ourselves as having helped our valued business travelers
                    find the perfect venue, arrange catering, and provide
                    transportation and accommodation for their guests.
                  </p>
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    Furthermore, we also provide translation services for your
                    business documents and presentations. We can translate your
                    documents into Arabic or any other language you need.
                    Moreover, if you require business networking assistance,
                    then look no further. Elevate your business endeavors with
                    our invaluable networking assistance as we facilitate
                    meaningful connections with the right stakeholders in Qatar,
                    organizing pivotal meetings and bespoke events. Committed to
                    unparalleled excellence, we enhance your business travels
                    with the utmost productivity and fulfillment.
                  </p>
                </div>
              </span>
            </div>
          </div>
          <div class="lufcontainer wow fadeInUp">
            <div class="lufcontent">
              <span>
                <div class="lufcontent-overlay"></div>
                <div className=" w-100 lufcontent-overlay2">
                  <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <div className="d-flex align-items-center mb-3">
                      <img src={pluselement} />
                      <div className="plustitle-ml-60">
                        <h1 className="fs-1 fw-bold text-white">LUXURY</h1>
                        <h1 className="fs-1 fw-bold text-white">TRAVEL</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="lufcontent-image" src={burjalarab} alt="" />

                <div class="lufcontent-details luffadeIn-bottom">
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    Experience the epitome of Luxury Travel, meticulously
                    crafted by Lufthansa City Center Space Travel to captivate
                    discerning leisure travelers. Our commitment transcends the
                    ordinary, ensuring bespoke leisure tours of all scales with
                    seamless transportation, impeccable itineraries, and opulent
                    accommodations.
                  </p>
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    Immerse yourself in the untouched splendor of Qatar through
                    our mesmerizing desert safaris and enchanting dhow cruises,
                    granting unparalleled perspectives of the region. Unveil
                    Qatar's rich heritage and culture with our bespoke cultural
                    tours, conducted by insightful local guides. Elevate your
                    journey with various exclusive activities that resonate with
                    your preferences.
                  </p>
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    As a dedicated DMC, our services deliver personalized
                    encounters, cultural emersion, and access to hidden
                    treasures. Relish the allure of luxury with Lufthansa City
                    Center Space Travel, where cherished memories abound
                    throughout your leisurely odyssey in Qatar.
                  </p>
                </div>
              </span>
            </div>
          </div>
          <div class="lufcontainer wow fadeInRight">
            <div class="lufcontent">
              <span>
                <div class="lufcontent-overlay"></div>
                <div className=" w-100 lufcontent-overlay2">
                  <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <div className="d-flex align-items-center mb-3">
                      <img src={pluselement} />
                      <div className="plustitle-ml-60">
                        <h1 className="fs-1 fw-bold text-white">MICE</h1>
                        <h1 className="fs-1 fw-bold text-white">&nbsp;</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="lufcontent-image" src={mice} alt="" />
                <div class="lufcontent-details luffadeIn-bottom">
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    As a reliable partner for MICE events, we offer an extensive
                    range of services to ensure a seamless and memorable
                    experience. Our expertise lies in selecting the ideal
                    venues, whether grand conference centers or intimate meeting
                    rooms.
                  </p>
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    Delight your guests with our impeccable catering, offering
                    various options from simple snacks to extravagant banquets.
                    Transportation worries are on us as we arrange seamless
                    airport transfers and shuttle buses. Our accommodation
                    arrangements cater to diverse preferences, from luxurious
                    hotels to budget-friendly guesthouses.
                  </p>
                  <p class="lufcontent-text fw-normal my-0 py-0">
                    Elevate your event with our curated activities, fostering
                    team-building and cultural exploration. Count on us for
                    meticulous logistics, from booking speakers to printing name
                    tags. Our dedicated and experienced staff will be there
                    every step of the way, ensuring a smooth and successful
                    event.
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceLufthansa;
