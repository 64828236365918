import React, { useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";
import biglogopng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import pluselement from "../img/pluselement.png";
import semicircledownelement from "../img/semicircledownelement.png";
import semicircleelement from "../img/semicircleelement.png";
import uparrowelement from "../img/uparrowelement.png";
import water1element from "../img/water1element.png";
import waveselementdark from "../img/waveselementdark.png";
import smallcirclepng from "../img/smallcircle.png";
import serviceimg1 from "../img/serviceimg1.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Responsiveall.css";
import"../css/globarpartner.css";
import "../css/service.css";
import "../js/script";

const ServicesSlide1 = ({ title, title2, description }) => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);
  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }
    console.log("hi");

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    
    <div className="container ">
      <div className="serviceroundtxttechnology "
     >
        {" "}
        
        <div className="wow rotateIn "style={{ visibility: "visible", animationName:"rotateIn" }} >
                {" "}
                <img src={pluselement} className="wow rotateIn"alt="" />
            </div>
        <div className="uparrowelementservice">
          {" "}
          <img src={uparrowelement} alt="" className="ani-top-bottom" />
        </div>
      </div>
      {/* <div className="serviceelement2 ">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div> */}
      <div className="serviceelement1bot">
        {" "}
        <img
          src={element1png}
          className="wow pulse"
          data-wow-delay="300ms"
          data-wow-iteration="infinite"
          data-wow-duration="2s"
          alt=""
        />
      </div>
      <div className="smlogoglobal">
        <img src={biglogopng} alt=""  />
      </div>
      {/* <div className="pluselement">
                    {" "}
                    <img src={pluselement} alt="" />
                  </div> */}
      {/* <div className="semicircledownelement">
                    {" "}
                    <img src={semicircledownelement} alt="" />
                  </div> */}

      {/* <div className="uparrowelement">
                    {" "}
                    <img src={uparrowelement} alt="" className="ani-top-bottom" />
                  </div> */}
      <div className="water1element ani-move d-flex">
        {" "}
        <div className="semicircleelement">
          {" "}
          <img src={semicircleelement} alt="" />
        </div>
        <img src={water1element} alt="" />
      </div>
      <div className="waveselementdark d-flex ani-move">
        {" "}
        <div className="smallcircleelement">
          {" "}
          <img src={smallcirclepng} alt="" />
        </div>
        <div className="semicircledownelement">
          {" "}
          <img src={semicircledownelement} alt="" />
        </div>
        <img src={waveselementdark} className="" alt="" />
      </div>
      {/* <div className="smallcircleelement">
                    {" "}
                    <img src={smallcirclepng} alt="" />
                  </div> */}
      <div className="contentpartservicesAT ">
     
        <div className="d-flex align-items-center">
          
          <div className="wow slideInLeft servicehead" data-wow-duration="1.2s">
          
            {title}
           
          </div>
          <div className=" " >
                {" "}
                <img src={pluselement} className="wow rotateIn"alt="" />
            </div>
        </div>
      
        <div
          className=" wow slideInRight Pyara"
          data-wow-delay=".1s"
          data-wow-duration=".80s"
        >
          {description}
        </div>
        {/* <div className="serviceelement2 "> */}{" "}
        <img src={element2png} alt="" className="ani-left-right" />
        {/* </div> */}
      </div>
    </div>
  );
};

export default ServicesSlide1;
