import axios from "axios";
import { DOMAIN, PORT, SCHEME } from "../Constants/Constants";

class RouteServices {
    gethomebannerbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethomebanner/${id}`);
    };

    gethomebanners() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethomebanners`)
    }

    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' })
    }
}

export default new RouteServices();