import React, { useEffect, useState } from "react";

import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "./img/biglogoservice.png";
import roundtxtpng from "./img/roundtxt.png";
import element1png from "./img/element1.png";
import element2png from "./img/element2.png";

import uparrowelement from "./img/uparrowelement.png";

import mannairoundtxt from "./img/Mannai Circle 001@72x.png";
import termsBanner from "./img/TermsOfUseBanner.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "./css/animate.min.css";
import "./css/ionicons.min.css";
import "./css/style.css";
import "./css/Terms&Conditions.css";
import "./css/globarpartner.css";
import "./js/timeline";
import "./js/script";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ReactGA from "react-ga4";
import ComponentServices from "../Components/ComponentServices";

const TermsandConditions = () => {
  const [metatitle, setmetatitle] = useState("Terms And Conditions | Mannai Travel")

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.termsandconditions && ev?.data?.termsandconditions?.title ? ev?.data?.termsandconditions?.title : "Terms And Conditions | Mannai Travel");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.termsandconditions && ev?.data?.termsandconditions?.title ? ev?.data?.termsandconditions?.title : "Terms And Conditions | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Terms And Conditions | Mannai Travel" });
        console.error("Error fetching SEO data: ", err);
      });
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div className="main-body theme-orange dark-horizontal termsandcondition overflow-hidden">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title={metatitle} />
          <Header />

          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={termsBanner}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>

              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>

              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    TERMS OF USE
                  </h3>
                  <div className="leftarrowanimationelement">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            <div className="col-12 col-md-2 col-lg-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5"></div>
            </div>
            <div className="col-12 col-md-7 col-lg-5 justify-content-center align-items-center padmob">
              <div className="mx-4 testing">
                <h2
                  className="text-white termpart"
                  style={{ textAlign: "left" }}
                >
                  Mannai Travel
                </h2>
                <br />
                <h2
                  className="text-white  termpart"
                  style={{ textAlign: "left" }}
                >
                  Terms & Conditions
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4">
              <h3
                className="text-center text-white mt-5  termupdate"
                style={{ textAlign: "left" }}
              >
                UPDATED DATE : 10-10-23
              </h3>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="row mobborderbottom">
              <div className="col-12 col-lg-12  wow fadeInRight ">
                <div className="cdecontentcd py-5">
                  <h3 className="text-white  ">Effective Date: 10-10-23</h3>
                  <p className="text-white ">
                    Please read these Terms and Conditions carefully before
                    using the Mannai Travel Qatar website (the "Website"). By
                    accessing or using the website, you agree to be bound by
                    these Terms and Conditions. If you do not agree to these
                    terms, please do not use the Website.
                  </p>
                  <ol>
                    <li className="text-white abouttext mt-2">
                      Use of the Website
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        The Website is provided for informational and booking
                        purposes related to the travel services offered by
                        Mannai Travels Qatar. You may use the Website for
                        personal, non-commercial use and in accordance with
                        these Terms and Conditions.
                      </li>

                      <li className="text-white my-2 ">
                        You must be at least 18 years old to use the Website. If
                        you are under 18, you may only use the Website under the
                        supervision of a parent or legal guardian.
                      </li>
                      <li className="text-white my-2">
                        You agree not to use the Website for any unlawful or
                        prohibited purpose and not to violate any local,
                        national, or international laws and regulations.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Booking and Reservations
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        When making reservations or bookings through the
                        Website, you agree to provide accurate, current, and
                        complete information, and you are responsible for any
                        changes to or cancellations of reservations as per our
                        cancellation policy.
                      </li>
                      <li className="text-white my-2">
                        Mannai Travel Qatar reserves the right to cancel or
                        refuse any booking for any reason at our discretion. We
                        may require additional information to confirm your
                        booking.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Content and Intellectual Property
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        The content on the Website, including text, graphics,
                        images, logos, and software, is the property of Mannai
                        Travels Qatar and is protected by copyright and other
                        intellectual property laws. You may not reproduce,
                        distribute, or use the content without our written
                        permission.
                      </li>
                      <li className="text-white my-2">
                        Any trademarks, logos, or service marks displayed on the
                        Website are registered and unregistered trademarks of
                        Mannai Travel Qatar. You may not use these marks without
                        our written permission.
                      </li>
                    </ol>
                    <li className="text-white abouttext  mt-2">Privacy</li>
                    <ol>
                      <li className="text-white my-2">
                        Our use of your personal information is governed by our
                        Privacy Policy, which can be found on our Website. By
                        using the Website, you consent to the collection, use,
                        and sharing of information as described in our Privacy
                        Policy.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Disclaimer</li>
                    <ol>
                      <li className="text-white my-2">
                        The information provided on the Website is for general
                        informational purposes only. While we strive to provide
                        accurate and up-to-date information, we do not guarantee
                        the accuracy, completeness, or reliability of the
                        content.
                      </li>
                      <li className="text-white my-2">
                        Mannai Travel Qatar is not responsible for any errors or
                        omissions on the Website, and we reserve the right to
                        correct any inaccuracies.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Limitation of Liability
                    </li>
                    <ol>
                      <li className="text-white my-2 ">
                        Mannai Travel Qatar and its affiliates shall not be
                        liable for any direct, indirect, incidental, special, or
                        consequential damages arising out of or in any way
                        connected with your use of the Website.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Termination</li>
                    <ol>
                      <li className="text-white my-2 ">
                        We reserve the right to terminate or suspend your access
                        to the Website at our sole discretion, without notice,
                        for any reason, including breach of these Terms and
                        Conditions.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Changes to Terms and Conditions
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        We may update or modify these Terms and Conditions at
                        any time. Any changes will be effective upon posting on
                        the Website with a new effective date. It is your
                        responsibility to review the Terms and Conditions
                        regularly.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Contact Us</li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        If you have any questions or concerns about these Terms
                        and Conditions, please contact us at
                        <a
                          className="text-decoration-none"
                          href="mailto: termsofuse@mannai.com"
                        >
                          {" "}
                          termsofuse@mannai.com.{" "}
                        </a>
                      </li>
                      <li className="text-white my-2">
                        Thank you for using Mannai Travel Qatar. We hope you
                        have a great experience using our Website and our travel
                        services.
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;
