import React, { useEffect } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import element2png from "../img/element2.png";

import kashmir from "../img/KASHMIR .jpeg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../js/timeline";
import "../js/script";

import "../css/mannaiholiday.css";

const MannaiHolidaySection2 = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <>
      <div
        className="main-body theme-orange dark-horizontal mannaiholidaysection2 overflow-hidden"
        id="MannaiHoliday"
      >
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="Mannai Holiday | Mannai Travel" />
            <div className="container-fluid ">
              <div className="d-flex  flex-lg-row flex-column customheight">
                <div className=" mannaibluedarkbg col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column  align-items-center p-md-4 p-2 ">
                  <p
                    className="mannaiholidaytextp p-md-4 p-2  wow slideInLeft  "
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    With a continued focus on customer centricity and our goal
                    of ensuring top-quality products and services to our
                    customers, we established Mannai Holidays, our in-house
                    destination Management division, in 1995.
                    <br />
                    <br />
                    Mannai Holidays offers comprehensive leisure travel service
                    provider, offering solutions for both inbound and outbound
                    clientele who trust us for our insights and personalized
                    plans and packages we offer. Today, it is one of Qatar's
                    preferred leisure travel providers, offering exclusive
                    holiday experiences within Qatar and worldwide.
                    <br />
                    <br />
                    Our experienced and multi-lingual professionals work out
                    personalized holiday packages with exclusive itineraries to
                    suit the specific needs of locals, Arabs, expatriates, and
                    businesses.
                    <br />
                    <br />
                    We are the exclusive General Sales Agent (GSA) for SOTC,
                    Thomas Cook, Rail Europe, and Flexible Auto.
                    <br />
                    <br />
                    To summarize, we are a widely preferred one-stop solution
                    for discerning travelers looking for quality services at
                    competitive rates. Our diverse and multicultural team of
                    talented and skilled professional executives is at your
                    beck-and-call 24/7, 365 days a year.
                  </p>
                  <div className="m-0">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                </div>
                <div className="d-flex align-items-stretch ">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column flex-grow-1 customimg">
                    <img src={kashmir} alt="" className=" img-fluid " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MannaiHolidaySection2;
