import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logopng from "../Pages/img/Mannai PNG logo@72x.png";
import biglogopng from "../Pages/img/biglogobg.png";
import element2png from "../Pages/img/element2.png";
import pluselement from "../Pages/img/pluselement.png";
import uparrowelement from "../Pages/img/uparrowelement.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/footer.css";
import "../Pages/js/script";
import ComponentServices from "./ComponentServices";
import moment from "moment";
import ReactGA from "react-ga4";

const Footer = () => {

  //Required to invoke model based alert at will
  const modRef = useRef(null);

  //required to change the copyright year dynamically without any manual intervention by the developer
  const date = new Date();
  const CRyear = moment(date).format("YYYY");

  const [socialmediadat, setsocialmediadat] = useState({})
  const [fooimg, setfooimg] = useState(null);
  const [bgcol, setbgcol] = useState(null);
  const [email, setemail] = useState('');
  const [footmodContent, setfootmodContent] = useState('');

  useEffect(() => {

    ComponentServices
      .getsocialmedia()
      .then((es) => setsocialmediadat(es?.data))
      .catch((err) => console.error("Error fetching SocialMedia Links: ", err));

    ComponentServices
      .getfooter()
      .then((es) => {
        const [tablename, date, fil] = es?.data?.imagePath.split('/');

        const col = JSON.parse(es?.data?.color)

        setbgcol(col.hex);

        ComponentServices
          .getImage(tablename, date, fil)
          .then((res) => setfooimg(res.data))
          .catch((err) => console.error("Error fetching BG Image: ", err));

      })
      .catch((err) => console.error("Error fetching Footer Data: ", err));

  }, [])

  //SMTP Email Functionality starts
  const handleEmailChange = (dat) => {
    setemail(dat);
  };

  const handleMailSubmit = () => {
    const emailRegexPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
    const emailRegex = new RegExp(emailRegexPattern);

    ReactGA.event({
      category: "Form Submission",
      action: "Click",
      label: "NewsLetter Subscription",
      value: "NewsLetter Subscription",
    });

    if (emailRegex.test(email)) {
      const dat = {
        email: email
      };

      setfootmodContent(`Thank you for subscribing to our NewsLetter. You'll receive a confirmation mail from us.`);
      modRef.current.click();

      ComponentServices
        .addsubscription(dat)
        .then(() => {
          // alert("Thank you for subscribing to our NewsLetter. You'll receive a confirmation mail from us.")
          // setmodContent(`Thank you for subscribing to our NewsLetter. You'll receive a confirmation mail from us.`);
          // modRef.current.click();
        })
        .then(setemail(''));

    } else {
      console.log('Invalid email address');
      setfootmodContent('Please Enter a valid email address');
      modRef.current.click();
    }
  }
  //SMTP Funtionality Ends

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handleHyperLinksGA = (des) => {
    handleScrollToTop();
    ReactGA.event({
      category: "Footer HyperLink Selection",
      action: "Click",
      label: des,
      value: des,
    });
  };

  const handleSocialMediaGA = (des) => {
    ReactGA.event({
      category: "Footer SocialMedia Selection",
      action: "Click",
      label: des,
      value: des,
    });
  };

  const handleContactGA = (des) => {
    ReactGA.event({
      category: "Footer Contact Selection",
      action: "Click",
      label: des,
      value: des,
    });
  }

  return (
    <footer
      id="footer"
      className="col-md-12 row position-relative m-0 overflow-hidden"
      style={{ background: `${bgcol}` }}
    >

      {/*DO NOT REMOVE  --Customized Model Hidden Button */}
      <button hidden type="button" class="btn btn-primary"
        ref={modRef}
        data-bs-toggle="modal"
        data-bs-target="#lameModel"
      ></button>

      {/*DO NOT REMOVE --Customized Model Based Alert */}
      <div class="modal fade" id="lameModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body d-flex justify-content-between align-items-center">
              <div>{footmodContent}</div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
      </div>

      <div className="logomannaifooter">
        <img src={logopng} alt="" />
      </div>

      <div>
        <div className="footerlogo">
          {" "}
          <img src={biglogopng} alt="" />
        </div>
        <div className="mt-4 ">
          {" "}
          <img src={element2png} alt="" className="ani-left-right" />
        </div>
      </div>
      <div className="pluselementfooter wow rotateIn">
        {" "}
        <img src={pluselement} alt="" />
      </div>

      <div className="uparrowelementfooter">
        {" "}
        <img src={uparrowelement} alt="" className="ani-top-bottom" />
      </div>
      <div
        className="wow fadeInLeft  col-md-5  d-flex justify-content-center align-items-center footerimage"
        data-wow-duration="1000ms"
        data-wow-delay="0ms"
        style={{
          visibility: "visible",
          animationDuration: "1000ms",
          animationDelay: "0ms",
          animationName: "fadeInLeft",
        }}
      >
        {" "}
        {fooimg && <img height={400} src={URL.createObjectURL(fooimg)} alt="" />}
      </div>
      <div className="col-md-7 row pb-4">
        <div className="col-md-12 ">
          <div className="wow fadeInUp text-white mt-3">
            <div className="d-flex align-items-center marginleftforfooter1">
              <div className="mb-2 wow rotateIn rotatee">
                {" "}
                <img src={pluselement} alt="" />
              </div>
              <div className="marginleftforfooter">
                <h2 className="textwrap-no p-mb-3 fs-1">
                  THE NEW FACE OF TRAVEL
                </h2>
                <h2 className="mb-3 fs-1">GET IN TOUCH</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-10 footersub pe-4  pb-2">
                <input
                  type="text"
                  className="form-control form-control-custom"
                  placeholder="Enter your email for newsletter subscription"
                  value={email}
                  onChange={(ev) => handleEmailChange(ev.target.value)}
                />
              </div>
              <div className="submitbtn col-lg-4  ">
                {" "}
                <a className="btn btn-primary mr-4 fr submitbtn" onClick={() => handleMailSubmit()} >
                  SUBMIT <i className="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 footer-content-flash ">
          <div className="col-lg-3 col-6  mb-3 wow fadeInUp">
            <h5 className="fs-5">COMPANY </h5>
            <ul className="footer-nav">
              <Link
                to="/Whoweare"
                className="text-decoration-none text-white"
                onClick={() => handleHyperLinksGA("Who We Are")}
              >
                <li>WHO WE ARE </li>
              </Link>
              <Link
                to="/Services"
                className="text-decoration-none text-white"
                onClick={() => handleHyperLinksGA("What We Do")}
              >
                <li>WHAT WE DO </li>
              </Link>

              <Link
                to="/connectus"
                className="text-decoration-none text-white"
                onClick={() => handleHyperLinksGA("Contact Us")}
              >
                <li>CONTACT US </li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-3 col-6  mb-3 wow fadeInUp">
            <h5 className="fs-5">LEGAL</h5>
            <ul className="footer-nav">
              <Link
                to="/privacypolicy"
                className="text-decoration-none text-white"
                onClick={() => handleHyperLinksGA("Privacy Policy")}
              >
                <li>PRIVACY POLICY </li>
              </Link>
              <Link
                to="/termsandconditions"
                className="text-decoration-none  text-white"
                onClick={() => handleHyperLinksGA("Terms Of Use")}
              >
                <li>TERMS OF USE </li>
              </Link>
              <Link
                to="/cookiepolicy"
                className="text-decoration-none  text-white"
                onClick={() => handleHyperLinksGA("Cookie Policy")}
              >
                <li>COOKIE POLICY</li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-4 col-12  mb-3 wow fadeInUp">
            <h5 className="fs-5">REACH US</h5>
            <div className="contact-item">
              Address : Building No. 72,
              <br />
              Zone No. 57
              <br />
              East Industrial Street,
              <br />
              PO Box 76, Doha, Qatar
              <br />
              Phone :{" "}
              <a className="text-decoration-none" href="tel:+97444282655" onClick={() => handleContactGA("Footer Contact: Tel")}>
                +974 – 4428 2655
              </a>
              <br />
              Email :{" "}
              <a
                className="text-decoration-none"
                href="mailto:travel.services@mannai.com.qa"
                onClick={() => handleContactGA("Footer Contact: Email")}
              >
                {" "}
                travel.services@mannai.com.qa{" "}
              </a>
            </div>
          </div>
        </div>
        <div>
          <ul className="footersocial social-rounded text-white d-flex">
            <li>
              <a
                href={socialmediadat?.facebook}
                onClick={() => handleSocialMediaGA("Facebook")}
              >
                <i className="socicon-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href={socialmediadat?.instagram}
                onClick={() => handleSocialMediaGA("Instagram")}
              >
                <i className="socicon-instagram"></i>
              </a>
            </li>
            <li>
              <a
                href={socialmediadat?.x}
                onClick={() => handleSocialMediaGA("Twitter")}
              >
                <i className="socicon-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href={socialmediadat?.linkedin}
                onClick={() => handleSocialMediaGA("LinkedIn")}
              >
                <i className="socicon-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href={socialmediadat?.snapchat}
                onClick={() => handleSocialMediaGA("Snapchat")}
              >
                <i className="socicon-snapchat"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="footercopyright text-center wow fadeInUp mt-2 fs-6">
          {" "}
          COPYRIGHT &copy;{CRyear} MANNAI TRAVEL DOHA QATAR
        </div>
      </div>
    </footer>
  );
};

export default Footer;
