import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import WOW from "wow.js";
import { ReactTitle } from "react-meta-tags";

import Header from "../../Components/Header";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";

import element1png from "../img/element1.png";
import element1dark from "../img/Grey Plus72.png";
import element2png from "../img/element2.png";

import uparrowelement from "../img/uparrowelement.png";

import techbannerimg from "../img/technologybanner (1).jpg";

import whitelogotech from "../img/whitelogotech.png";

import talkimg from "../img/talkimg.png";
import techgraylogo from "../img/techgraylogo.png";
import toparrowtech from "../img/toparrowtech.png";
import dashboard1 from "../img/dashboard1.png";
import dashboard2 from "../img/dashboard2.png";

import hoarr from "../img/Blue Arrows.png";

import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import "wow.js";

import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
// import "./css/ionicons.min.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/technology.css";
import "../js/script";
import Footer from "../../Components/Footer";
import Requestform from "../../Components/Requestform";
import GlobalPartner from "../GlobalPartnersPage/GlobalPartner";
import GlobalPartnerForm from "../GlobalPartnersPage/GlobarPartnerForm";
import InhouseTechnology from "./InhouseTechnology";
import Partneredtechnology from "./Partneredtechnology";
import ReactGA from "react-ga4";
import ComponentServices from "../../Components/ComponentServices";

const Technologies = () => {
  const [selected, setSelected] = useState("IN-HOUSETECHNOLOGY SOLUTIONS");
  const [metatitle, setmetatitle] = useState("Technologies | Mannai Travel")
  const location = useLocation();

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.technologies && ev?.data?.technologies?.title ? ev?.data?.technologies?.title : "Technologies | Mannai Travel")
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.technologies && ev?.data?.technologies?.title ? ev?.data?.technologies?.title : "Technologies | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Technologies | Mannai Travel" });
        console.error("Error fetching SEO data: ", err)
      })
  }, []);

  useEffect(() => {
    document.body.style.removeProperty("overflow");
    document.body.style.overflowY = "scroll";
    document.body.style.overflowX = "hidden";

    $(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleInHouseTech = () => {
    setSelected("IN-HOUSETECHNOLOGY SOLUTIONS");
    handleonclickGA("IN-HOUSETECHNOLOGY SOLUTIONS");
  };

  const handlePartneredTech = () => {
    setSelected("PARTNERED TECHNOLOGY SOLUTIONS");
    handleonclickGA("PARTNERED TECHNOLOGY SOLUTIONS");
  };

  const handleonclickGA = (de) => {
    ReactGA.event({
      category: de === "IN-HOUSETECHNOLOGY SOLUTIONS" || "PARTNERED TECHNOLOGY SOLUTIONS" ? "Content Change on Click" : de === "MAIL TO" ? "Onclick MailTo" : "Vertical Scroll",
      action: "Click",
      label: de,
      value: de
    });
  };

  return (
    <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
      <div className="animsition overflow-hidden">
        <div className="wrapper">
          <ReactTitle title={metatitle} />
          <Header />

          <div className="slide-container">
            <div className="slide-bg">
              <div className="inside opacityBG ">
                <img
                  src={techbannerimg}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>

              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>
              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    TECHNOLOGY
                  </h3>
                  <p
                    className="normaltext  wow slideInRight"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    At Mannai Travel, we pay attention to the updated transition
                    from traditional booking platforms to a comprehensive and
                    robust travel management system. Our systems do not only
                    provide insights into your travel expenses but also offer
                    guidance on intelligent cost-saving strategies for your
                    travel expenditures.
                  </p>
                  <div className="readbutton">
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    <a className="btn btn-primary   " href="#technologies" onClick={() => handleonclickGA("READ MORE")}>
                      READ MORE <i className="icon ion-ios-arrow-thin-down"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section p0" id="technologies">
            <div className="container-fluid">
              <div className="techlogobg">
                <div className="slide-body">
                  <div className="row ">
                    <div className="col-lg-4 d-flex flex-direction-column justify-content-center mt-5 text-center">
                      <div className=" wow rotateIn">
                        <img src={whitelogotech} alt="" />
                        <h2 className="text-white mt-2 technologyexp">
                          {" "}
                          TRAVEL TECHNOLOGY EXPERTS{" "}
                        </h2>
                      </div>
                    </div>

                    <div className="col-lg-8 d-flex justify-content-center align-items-center">
                      <div
                        className=" wow fadeInRight"
                        data-wow-delay="1s"
                        data-wow-duration="1.5s"
                      >
                        <h2 className="text-left text-white border-bottom-dash pb-3">
                          Let Mannai Travel help you
                          <br /> streamline your travel with <br />
                          ease and assurance
                        </h2>
                        <h2 className="text-left fs-4 fw-semibold text-white pt-3">
                          Reach out to get started today.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="darkblunbg1">
            <div className="second-page-wrapper">
              <div className="second-page-top d-flex   fs-4 fw-bold text-white h-100">
                <div
                  role="button"
                  className={`cargo w-50 h-100 p-2 ${selected === "IN-HOUSETECHNOLOGY SOLUTIONS"
                    ? ""
                    : "bg-primary"
                    }`}
                  onClick={() => handleInHouseTech()}
                >
                  <div
                    className={`travel d-flex justify-content-around align-items-center text-center  h-100 p-5 wow slideInLeft ${selected === "IN-HOUSETECHNOLOGY SOLUTIONS"
                      ? "border border-light"
                      : ""
                      }`}
                    data-wow-delay="0.6s"
                    data-wow-duration="1.4s"
                  >
                    IN-HOUSE TECHNOLOGY SOLUTIONS
                  </div>
                </div>
                <div
                  role="button"
                  className={`cargo w-50 h-100  ${selected === "PARTNERED TECHNOLOGY SOLUTIONS"
                    ? "p-2"
                    : "bg-primary p-2"
                    }`}
                  onClick={() => handlePartneredTech()}
                >
                  <div
                    className={`h-100 d-flex justify-content-around align-items-center text-center p-5 w-100 wow slideInRight ${selected === "PARTNERED TECHNOLOGY SOLUTIONS"
                      ? "border border-light"
                      : ""
                      }`}
                    data-wow-delay="0.6s"
                    data-wow-duration="1.4s"
                  >
                    PARTNERED TECHNOLOGY SOLUTIONS
                  </div>
                </div>
              </div>
              {selected === "IN-HOUSETECHNOLOGY SOLUTIONS" ? (
                <>
                  <InhouseTechnology />
                </>
              ) : (
                <>
                  <Partneredtechnology />
                </>
              )}
            </div>
          </div>

          {selected === "IN-HOUSETECHNOLOGY SOLUTIONS" ? (
            <>
              <div className=" appsup  bg-primary  px-5 py-4">
                <h3 className="wow bounceIn">Have a Question ?</h3>
                <p className="appsuptxt wow fadeInDown">
                  {" "}
                  Write email to us our team will swiftly get back to you.
                </p>
                <p className="wow fadeInUp ">
                  <a
                    href="mailto: travel.services@mannai.com.qa"
                    className="text-decoration-none"
                    onClick={() => handleonclickGA("MAIL TO")}
                  >
                    travel.services@mannai.com.qa
                  </a>
                </p>
              </div>

              <div className="section section-item p0 talkteam">
                <div className="slide-body ">
                  <div className="row ">
                    <div className="col-lg-5 z-index-3">
                      <div className="talkimg ">
                        <img
                          src={talkimg}
                          className="wow fadeInLeft"
                          alt=""
                          data-wow-delay="0.2s"
                          data-wow-duration="1s"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 d-flex flex-column align-items-baseline p-5">
                      <h4
                        className="mb-2 border-bottom border-black pb-1 wow slideInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        TRAVEL MOBILITY
                      </h4>
                      <p
                        className="fs-6 wow slideInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        Our One-Stop Mobile App. Solution for effortless travel.
                      </p>

                      <p
                        className="text-align-justify mt-3 wow fadeInRight"
                        style={{ paddingRight: "10px" }}
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        {" "}
                        State-of-the-art technology is the heart of any
                        business, and we reflect it through our travel products,
                        especially our Travel Mobility solutions that cater to
                        all your travel requirements. Our robust mobile
                        application works flawlessly on both platforms, viz.
                        Android and iOS. Here, customers can book flights,
                        hotels, visas, and tours through their smartphone with
                        just one tap, anywhere and anytime.
                      </p>
                      <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
                        <div className="d-flex flex-column mt-4 align-items-start">
                          <img
                            src={hoarr}
                            alt=""
                            className="ani-left-right mb-2 pl-2"
                            width="80px"
                          />
                          <a
                            className="btn btn-primary wow fadeInLeft  learnmore"
                            data-wow-delay="0.50s"
                            data-wow-duration="1.2s"
                            href="#"
                            onClick={() => handleonclickGA("LEARN MORE")}
                          >
                            LEARN MORE{" "}
                            <i className="icon ion-ios-arrow-thin-right"></i>
                          </a>
                        </div>
                        <div className="">
                          <img
                            src={element1dark}
                            alt=""
                            width="50%"
                            className="wow pulse"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1"></div>

                    <div className="dashboardpart col-lg-12 row">
                      <div className="col-lg-8 ">
                        <div className="techgraylogo">
                          {" "}
                          <img
                            src={techgraylogo}
                            className="wow zoomIn techlogo"
                            alt=""
                            width="150px"
                          />
                        </div>
                        <div className="toparrowtech wow slideInRight">
                          {" "}
                          <img
                            src={toparrowtech}
                            data-wow-delay="10s"
                            data-wow-duration="5s"
                            alt=""
                            className="ani-top-bottom"
                          />
                        </div>

                        <div
                          className=" dashboardparttext z-index-3 position-relative  wow fadeInRight px-md-5 px-3"
                          data-wow-delay="0.2s"
                          data-wow-duration="1s"
                        >
                          <span>
                            &quot;Technology monitors what matters most&quot;
                          </span>
                          <h4>
                            COMPREHENSIVE REAL-TIME DATA <br />
                            FOR A FULL SPECTRUM OF INSIGHTS
                          </h4>
                          <br />
                          <span className="text-align-justify">
                            Frustrated by extended data delays? These delays can
                            hinder timely decision-making that has a direct
                            impact on your company's financial performance. Our
                            business intelligence and reporting tool provides
                            real-time data, delivering precisely the information
                            you require, exactly when you need it. Our
                            user-friendly interface ensures swift access to the
                            data necessary for effectively managing your
                            corporate travel program.
                          </span>
                        </div>
                        <a
                          className="btn btn-primary mt-4 ms-md-5 ms-4  wow fadeInLeft"
                          data-wow-delay="0.50s"
                          data-wow-duration="1.2s"
                          href="#"
                          onClick={() => handleonclickGA("EXPLORE MORE ON BI TOOLS")}
                        >
                          EXPLORE MORE ON BI TOOLS{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>
                        </a>
                      </div>

                      <div className="col-lg-4 position-relative  p0">
                        <div className="dashboard1">
                          <img
                            src={dashboard1}
                            className="wow fadeInRight"
                            data-wow-delay="0.5s"
                            data-wow-duration="1s"
                            alt=""
                          />
                        </div>
                        <div className="dashboard2">
                          <img
                            src={dashboard2}
                            className="wow fadeInRight"
                            data-wow-delay="1.2s"
                            data-wow-duration="1.2s"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <GlobalPartnerForm invoker={`Technologies`} />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Technologies;
