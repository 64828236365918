import React, { useEffect } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";

import blogsbg from "../img/BlogBanner.jpg";

import uparrowelement from "../img/uparrowelement.png";

import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../js/script";
import ReactGA from "react-ga4";
import Sec1jsonData from "./SectionData/Section1.json";

const Section1 = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Vertical Scroll",
      action: "Click",
      label: "READ MORE",
      value: "READ MORE"
    });
  }

  return (
    <div class="main-body theme-orange dark-horizontal section1 overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Blogs & News | Mannai Travel" />

          {Sec1jsonData.map((e) => {
            return (
              <div class="slide-container">
                {/* backgroundImage  */}
                <div class="slide-bg wow pulse">
                  <div className="inside opacityBG ">
                    <img
                      src={blogsbg}
                      alt=""
                      className=" img-fluid object-fit-cover"
                    />
                  </div>
                </div>
                {/* for all png in the banner container  */}
                <div class="container">
                  <div className="downplus wow rotateIn ">
                    {" "}
                    <img src={roundtxtpng} alt="" />
                  </div>

                  <div className="smlogoglobal">
                    {" "}
                    <img
                      src={biglogoservicepng}
                      alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                    />
                  </div>
                  <div className="downanimationelement">
                    <img
                      src={element1png}
                      alt=""
                      className="wow pulse"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="uparrowelementall">
                    {" "}
                    <img
                      src={uparrowelement}
                      alt=""
                      className="ani-top-bottom"
                    />
                  </div>
                  <div className="roundanimation">
                    <div className="roundtxtanimationplus">
                      {" "}
                      <img src={roundtxtpng} alt="" />
                    </div>
                    <div className="roundtextanimation">
                      {" "}
                      <img src={mannairoundtxt} alt="" />{" "}
                    </div>
                  </div>
                  <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                    <div className="">
                      <h3 class="wow slideInLeft " data-wow-duration="1.2s">
                        {e.Title}
                      </h3>
                      <p
                        class="normaltext  wow slideInRight"
                        data-wow-delay=".1s"
                        data-wow-duration=".80s"
                      >
                        {e.Desc}
                      </p>
                      <div className="readbutton">
                        <div className="leftarrowanimationelement">
                          {" "}
                          <img
                            src={element2png}
                            alt=""
                            className="ani-left-right"
                          />
                        </div>
                        <a className="btn btn-primary   " href="#Blogsandnews" onClick={() => handleonclickGA()}>
                          READ MORE{" "}
                          <i className="icon ion-ios-arrow-thin-down"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Section1;
