import axios from "axios";
import { DOMAIN, PORT, SCHEME } from "../Constants/Constants";

class ComponentServices {
    addsubscription(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addsubscribers`, dat);
    }

    getmenus() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmenus`);
    }

    getsocialmedia() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getsocialmedia/1`)
    }

    getfooter() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getfooter/1`)
    }

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };

    getseotags() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getseo`)
    }
}

export default new ComponentServices();