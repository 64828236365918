import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";
import biglogopng from "../img/biglogobg.png";
import homeicon from "../img/headerhome.png";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "socicon/css/socicon.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/Responsiveall.css";
import "../js/script";
import Header from "../../Components/Header";
import Banner1 from "./Banner1";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";
import Banner4 from "./Banner4";
import RouteServices from "../../Routes/RouteServices";
import ReactGA from "react-ga4";
import ComponentServices from "../../Components/ComponentServices";

const Home = () => {
  const [metatitle, setmetatitle] = useState("Home | Mannai Travel");
  const [homebannersdat, sethomebannersdat] = useState([]);

  useEffect(() => {

    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.home && ev?.data?.home?.title ? ev?.data?.home?.title : "Home | Mannai Travel")
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.home && ev?.data?.home?.title ? ev?.data?.home?.title : "Home | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home | Mannai Travel" });
        console.error("Error fetching SEO data: ", err)
      });

    RouteServices
      .gethomebanners()
      .then((ev) => {
        sethomebannersdat(ev.data)
        const tooltipdat = ev.data?.map((eb) => eb?.tooltip);

        $(function () {
          // Initialize PagePiling
          window.$(".a-horizontal").pagepiling({
            scrollingSpeed: 280,
            menu: "#menu",
            direction: "horizontal",
            loopTop: true,
            loopBottom: true,
            // anchors: ["HOME", "WHATWEDO", "TECHNOLOGY", "WHOWEARE"],
            // navigation: true,
            navigation: {
              textColor: "#000",
              bulletsColor: "#000",
              position: "right",
              // tooltips: ["HOME", "WHATWEDO", "WHOWEARE", "TECHNOLOGY"],
              tooltips: tooltipdat
            },
            afterLoad: function (anchorLink, index) {
              if (index === 1 || index === 2 || index === 3 || index === 4) {
                $(".mainbody").addClass("dark-horizontal");
              } else {
                $(".mainbody").removeClass("dark-horizontal");
              }
            },
          });
          $(".a-pp-prev").on("click", function () {
            window.$.fn.pagepiling.moveSectionUp();
          });
          $(".a-pp-next").on("click", function () {
            window.$.fn.pagepiling.moveSectionDown();
          });
        });
      })
      .catch((err) => console.error("Error fetching HomeBanners Data: ", err));


  }, [])


  useEffect(() => {
    $(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: false,
        // loadingParentElement: "body",
        loadingClass: "preloader",
        loadingInner:
          // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>', // e.g '<img src="loading.svg" />'
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        // transition: function (url) {
        //   location.href = url;
        // },
      });
    });
    new WOW().init();
    document.body.style.overflow = "hidden";

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-none");
      ppnav.classList.add("d-block");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }

  }, []);

  const toggleClassToHtml = () => {
    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    } else {
      htmlTag.classList.add("body-menu-opened");
      htmlTag.classList.remove("body-menu-close");
    }
  };

  return (
    <div className="mainbody theme-orange dark-horizontal homepage">
      <ReactTitle title={metatitle} />
      <CookieConsent
        location="bottom"
        buttonText="I Accept!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#17355d" }}
        buttonStyle={{ color: "#fff", fontSize: "13px", background: "#3fa9f5" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="animsition overflow-hidden">
        <div className="wrapper">
          <header id="header" className="header header-fixed">
            <div className="container-fluid mp0 clearfix">
              <div className="d-flex justify-content-between">
                {" "}
                <div className="p-2 px-5 py-4">
                  {" "}
                </div>
                <div className="d-flex h-fit-content ">
                  <div className="homeicon d-flex align-items-center mx-2">
                    <Link to="/">
                      <img
                        src={homeicon}
                        height={30}
                        width={30}
                        alt=""
                        className=""
                      />
                    </Link>
                  </div>
                  <button
                    className="nav-toggle-btn a-nav-toggle"
                    onClick={toggleClassToHtml}
                  >
                    {" "}
                    <span className="nav-toggle nav-toggle-sm">
                      {" "}
                      <span className="stick stick-1"></span>{" "}
                      <span className="stick stick-2"></span>{" "}
                      <span className="stick stick-3"></span>{" "}
                    </span>{" "}
                  </button>
                </div>
              </div>
            </div>
            <Header />
          </header>
          <div className="biglogo-fixed">
            {" "}
            <img src={biglogopng} alt="" />
          </div>

          <div class="home-horizontal a-horizontal full-height">

            {homebannersdat?.map((emf) => (
              emf?.bannertype === "BAN1"
                ? <Banner1 b1dat={emf?.bannerdat} />
                : emf?.bannertype === "BAN2"
                  ? <Banner2 b2dat={emf?.bannerdat} />
                  : emf?.bannertype === "BAN3"
                    ? <Banner3 b3dat={emf?.bannerdat} />
                    : <Banner4 b4dat={emf?.bannerdat} />
            ))};

          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
