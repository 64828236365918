import React, { useEffect, useState } from "react";
import WhatsAppIcon from "../Pages/img/whatsapp.png";
import "../Pages/css/WhatsAppButton.css";
import ComponentServices from "./ComponentServices";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

const WhatsAppButton = () => {

  const params = useParams();

  const [whatsappno, setwhatsappno] = useState("");

  useEffect(() => {

    ComponentServices
      .getsocialmedia()
      .then((res) => {
        const [spA, spB] = res.data?.phno.split("+");
        setwhatsappno(spB);
      })
      .catch((err) => console.error("Error fetching whatsapp number", err));
  }, [params])


  // const phoneNumber = "97444282655";

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: "Floating WhatsApp Icon",
      value: "Floatng WhatsApp Icom",
    });
  }

  return (
    <a
      // href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
      href={`https://api.whatsapp.com/send?phone=${whatsappno}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
      className="float"
      target="_blank"
      onClick={() => handleonclickGA()}
    >
      <img
        src={WhatsAppIcon}
        alt="WhatsApp Icon "
        className="my-float position-absolute"
      />
    </a>
  );
};

export default WhatsAppButton;
