import React, { useContext } from 'react';
// import shapebg from "../img/shapeimage.jpg";
import { blogContext } from './Blogsandnews';

const Shape = () => {
  const clipPathValue = 'M 0 250 Q 50 200 200 200 Q 300 200 350 150 A 50 50 0 1 1 800 500 Q 400 600 0 500 ';
  const [featureddata, featuredimg] = useContext(blogContext);


  return (
    <div style={{

      position: 'relative',
      width: '100%',
      height: '100%',

      background: 'linear-gradient(90deg, rgba(2, 112, 183, 1) 0%, rgba(85, 81, 140, 1) 100%)'
    }}>
      <svg width="100%" height="100%" viewBox="0 0 800 600" >
        <defs>
          <clipPath id="clipPath">
            <path d={clipPathValue} />
          </clipPath>
        </defs>
        <image
          className='img-fluid position-absolute'
          // href={shapebg}
          href={featuredimg}
          alt=""
          style={{
            clipPath: 'url(#clipPath)',
            //Do not include width of any sort, else it will wreck the svg image shape when the image dimensions are changed dynamically
            // width: '100%',
            height: '100%',
            objectFit: 'contain',
            opacity: 0.7,

          }}
        />

      </svg>
    </div>
  );
};

export default Shape;
