import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogopng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import semicircledownelement1 from "../img/semicircledownelement1.png";
import semicircleelement from "../img/semicircleelement.png";
import uparrowelement from "../img/uparrowelement.png";
import waveliteelement2 from "../img/waveliteelement2.png";
import wavedark2 from "../img/wavedark2old.png";
import smallcirclepng from "../img/smallcircle.png";
import leftarrowpng from "../img/leftarrow.png";
import "animsition";
import "animsition/dist/css/animsition.css";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Responsiveall.css";
import "../js/script";

const ServicesSlide2 = ({ title, title2, description }) => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);
  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }
    console.log("hi");

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div className="container">
    <div className="serviceroundtxttechnologyHA animsition">
      {" "}
      <div className="uparrowelementHAservice">
      {" "}
      <img src={uparrowelement} alt="" className="ani-top-bottom" />
    </div>
    <div className="wow rotateIn "style={{ visibility: "visible", animationName:"rotateIn" }} >
                {" "}
                <img src={pluselement} className="wow rotateIn"alt="" />
            </div>
    </div>
    {/* <div className="leftarrowHA ">
      {" "}
      <img src={leftarrowpng} alt="" className="ani-left-right " />
    </div> */}
    <div className="elementsmallx">
      {" "}
      <img
        src={elementsmallxpng}
        alt=""
        className="wow pulse"
        data-wow-delay="300ms"
        data-wow-iteration="infinite"
        data-wow-duration="2s"
      />
    </div>
    <div className="servciebiglogoha-fixed">
      {" "}
      <img src={biglogopng} alt="" />
    </div>
    {/* <div className="pluselementHA">
      {" "}
      <img src={pluselement} alt="" />
    </div> */}
    {/* <div className="semicircledownelementHA">
      {" "}
      <img src={semicircledownelement1} alt="" />
    </div> */}
    {/* <div className="semicircleelementHA">
      {" "}
      <img src={semicircleelement} alt="" />
    </div> */}
    {/* <div className="uparrowelementHA">
      {" "}
      <img src={uparrowelement} alt="" className="ani-top-bottom" />
    </div> */}
    <div className="waveliteelement2 d-felx ani-move">
      {" "}
      <img src={waveliteelement2} alt="" className="" />
      <div className="semicircleelementHA">
      {" "}
      <img src={semicircleelement} alt="" />
    </div>
    </div>
    <div className="wavedark2HA ani-move">
      {" "}
      <div className="semicircledownelementHA">
      {" "}
      <img src={semicircledownelement1} alt="" />
    </div>
    <div className="smallcircleelementHA">
      {" "}
      <img src={smallcirclepng} alt="" />
    </div>
      <img src={wavedark2} className="" alt="" />
    </div>
   
    <div className="contentpartservicesHA">
        <div className="d-flex flex-row  align-items-center justify-content-md-end justify-content-start">
        <div className="wow rotateIn "style={{ visibility: "visible", animationName:"rotateIn" }} >
                {" "}
                <img src={pluselement} className="wow rotateIn"alt="" />
            </div>
        
      <div className="wow slideInRight text-md-end text-start servicehead" data-wow-duration="1.2s">
        {title} 
        
      </div>
     
      </div>
      <div
        className=" wow slideInLeft Pyara"
        data-wow-delay=".1s"
        data-wow-duration=".80s"
      >
        {" "}
       {description}
      </div>
      <img src={leftarrowpng} alt="" className="ani-left-right " />
    </div>
  </div>
  );
};

export default ServicesSlide2;
