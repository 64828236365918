import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";
import $, { ready } from "jquery";
// import { ReactTitle } from "react-meta-tags";

// import WOW from "wow.js";

import leftbgimg from "../img/leftbg.png";
// import logopng from "../img/logo.png";
// import biglogopng from "../img/biglogobg.png";
import roundtxtpng from "../img/roundtxt.png";
import botlinepng from "../img/botline.png";
import verlinepng from "../img/verline.png";
import roundelementpng from "../img/roundelement.png";
// import elementbgpng from "../img/elementbg.png";
// import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
// import homevideo from "../img/homevideo.mp4";
import elementsmallxpng from "../img/elementsmallx.png";
// import atpng from "../img/airtravel.png";
// import rtpng from "../img/railticket.png";
// import mtpng from "../img/MeetGreet.png";
// import carcaroselpng from "../img/carrental.png";
// import homeicon from "../img/headerhome.png";
// import hotelpng from "../img/hotelaccomadation.png";
// import bgvideo from "../img/bg-video.jpg";
// by me
// import videoPause from "../img/pause1.png";
// import videoPlay from "../img/play1.png";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "socicon/css/socicon.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/Responsiveall.css";
import "../js/script";

// import imgg from "../img/bg-horizontal2.jpg"
import RouteServices from "../../Routes/RouteServices";
import ReactGA from "react-ga4";

const Banner2 = ({ b2dat }) => {

    const [bgimage, setbgimage] = useState(null);
    const [carousalimg, setcarousalimg] = useState([]);

    useEffect(() => {

        const fetchImages = async () => {

            const [tablename, date, file] = b2dat?.imagePath.split('/');

            RouteServices
                .getImage(tablename, date, file)
                .then((res) => setbgimage(res.data))
                .catch((err) => console.error("Error fetching background image: ", err));

            const carousalArray = b2dat?.imageslider?.split(',');

            const carousalPromises = carousalArray?.map(async (ev) => {
                const [tname, da, fi] = ev?.split('/');

                try {
                    const de = await RouteServices.getImage(tname.trim(), da, fi);
                    return de.data;
                    // return URL.createObjectURL(de.data);
                } catch (error) {
                    console.error("Error fetching Carousal Images: ", error);
                    return null;
                }
            });

            const carousalFin = await Promise.all(carousalPromises);
            setcarousalimg(carousalFin);

        };

        fetchImages()

    }, [])



    //old owlcarousal useeffect
    // useEffect(() => {
    //     $(function () {
    //         window.$(".owl-carousel").owlCarousel({
    //             items: 3,
    //             itemsDesktop: [1000, 3],
    //             itemsDesktopSmall: [979, 2],
    //             itemsTablet: [768, 2],
    //             itemsMobile: [650, 1],
    //             // pagination: true,
    //             loop: true,
    //             margin: 10,
    //             // nav: true,
    //             autoplay: true,
    //             autoplayTimeout: 3000,
    //             // autoplayHoverPause: true,
    //             center: true,
    //             navText: [
    //                 "<i class='fa fa-angle-left'></i>",
    //                 "<i class='fa fa-angle-right'></i>",
    //             ],
    //             // responsive: {
    //             //   0: {
    //             //     items: 1,
    //             //   },
    //             //   600: {
    //             //     items: 1,
    //             //   },
    //             //   1000: {
    //             //     items: 3,
    //             //   },
    //             // },
    //         });
    //     });
    // }, []);

    //test useeffect for owlcarousal

    useEffect(() => {
        // Initialize Owl Carousel once images are fetched
        if (carousalimg.length > 0) {
            // Initialize Owl Carousel
            $(function () {
                window.$(".owl-carousel").owlCarousel({
                    items: 3,
                    itemsDesktop: [1000, 3],
                    itemsDesktopSmall: [979, 2],
                    itemsTablet: [768, 2],
                    itemsMobile: [650, 1],
                    loop: true,
                    margin: 10,
                    autoplay: true,
                    autoplayTimeout: 3000,
                    center: true,
                    navText: [
                        "<i class='fa fa-angle-left'></i>",
                        "<i class='fa fa-angle-right'></i>",
                    ],
                    // Callback after initialization
                    onInitialized: function () {
                        // Update the number of items for loop effect
                        $(this).trigger('refresh.owl.carousel');
                    }
                })
            }).on('refreshed.owl.carousel', function (event) {
                // Calculate the number of cloned items
                const clonedItems = $(this).find('.owl-item.cloned').length;
                // Update the number of items for loop effect
                $(this).trigger('to.owl.carousel', [clonedItems / 2, 0, true]);
            });
        }
    }, [carousalimg]);

    const handleonclickGA = () => {
        ReactGA.event({
            category: "Page Redirection",
            action: "Click",
            label: b2dat?.buttonlabel,
            value: b2dat?.buttonlink,
        });
    }

    return (
        <div class="section pp-scrollable slide slide2 slide-dark">
            <div className="slide-container">
                <div className="slide-bg">
                    {/* <div className="inside" style={{ backgroundImage: `url(${imgg})` }}></div> */}
                    {bgimage ? <div className="inside" style={{ backgroundImage: `url(${URL.createObjectURL(bgimage)})` }}></div> : ""}
                </div>

                <div className="botline">
                    {" "}
                    <img src={botlinepng} alt="" />
                </div>
                <div className="verline">
                    {" "}
                    <img src={verlinepng} alt="" />
                </div>
                <div className="roundelement ">
                    {" "}
                    <img src={roundelementpng} alt="" />
                </div>
                {/* <div className="element1 float-bob-y">
        {" "}
        <img src={element1png} alt="" />
      </div> */}

                <div className="element1botWWA">
                    {" "}
                    <img
                        src={elementsmallxpng}
                        className="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                    />
                </div>
                <div className="roundanimation">
                    <div className="roundtxtanimationplus">
                        {" "}
                        <img src={roundtxtpng} alt="" />
                    </div>
                    <div className="roundtextanimation">
                        {" "}
                        <img src={mannairoundtxt} alt="" />{" "}
                    </div>
                </div>
                <div className="container">
                    <div className="contentpartwwd ">
                        <div
                            className="wow slideInLeft 
            NormalHeading"
                            data-wow-duration="1.4s"
                        >
                            {/* WHAT WE DO */}
                            {b2dat?.heading}
                        </div>
                        <div
                            className=" wow slideInRight text-white SubHeading"
                            data-wow-delay=".2s"
                            data-wow-duration=".95s"
                        >
                            {/* Embark on a global adventure with our comprehensive travel
            services. Whether it’s for Business or Leisure purposes,
            we'll get you there with our comprehensive travel
            services. */}
                            {b2dat?.title}
                        </div>
                        <div
                            className=" wow fadeInRight Pyara"
                            data-wow-delay="1.0s"
                            data-wow-duration="1.4s"
                        >
                            {/* Empowered by our seasoned team and cutting-edge
            technology, you have the freedom to savor your most
            cherished travel experiences. */}
                            {b2dat?.subtitle}
                        </div>{" "}
                        <div>
                            <div className="mb-1">
                                {" "}
                                <img
                                    src={element2png}
                                    alt=""
                                    className="ani-left-right"
                                />
                            </div>
                            <Link
                                // to="/Services"
                                to={b2dat?.buttonlink}
                                className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                                data-wow-delay="1.2s"
                                data-wow-duration="1.6s"
                                onClick={() => handleonclickGA()}
                            >
                                {/* EXPLORE{" "} */}
                                {b2dat?.buttonlabel}
                                <i className="icon ion-ios-arrow-thin-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="contentpartserviceswwd">
                        <div className="slider">
                            <div className="owl-carousel">
                                {carousalimg.map((img, index) => (
                                    <div className="slider-card" key={index}>
                                        <div className="justify-content-center align-items-center mb-4">
                                            <img src={URL.createObjectURL(img)} alt={`Image ${index}`} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className="wow fadeInLeft animated leftbg "
                        data-wow-duration="2000ms"
                        data-wow-delay="0ms"
                        style={{
                            visibility: "visible",
                            animationDuration: "2000ms",
                            animationDelay: "0ms",
                            animationName: "fadeInLeft",
                        }}
                    >
                        <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />{" "}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner2