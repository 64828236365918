import React from 'react'
import element2png from "../img/element2.png";
import image1 from "../img/ReportsonLaptop.jpg";
import pluselement from "../img/pluselement.png"
import ReactGA from "react-ga4";
const InhouseTechnology = () => {

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Vertical Scroll",
      action: "Click",
      label: "EXPLORE MORE",
      value: "EXPLORE MORE"
    });
  };

  return (

    <div className='row px-md-5 px-3 py-5'>
      <div className='col-md-6 py-3 pe-md-5 pe-sm-0'>
        <p className=' text-white text-align-justify fs-6 wow fadeInUp' data-wow-delay="0.6s"
          data-wow-duration="1.2s">
          Enhance your experience of Corporate Travel booking by leveraging our in-house business travel tool, which assists you in building your business travel itinerary by yourself or through our travel agents. The most efficient feature we provide is a dynamic payment option for our corporate customers, which makes the entire process easier for employers and employees to manage expenses. It effectively controls expenses by implementing the authority of approval at different levels and corporate travel policy compliance.
        </p>
        <p className=' text-white text-align-justify fs-6  wow fadeInUp' data-wow-delay="0.6s"
          data-wow-duration="1.2s">
          Our travel management tool is designed for everyone working in your organization, from travel managers who manage your organization's travel expenses to central bookers who book for the employees and employees who travel for business.
        </p>
        <div className="d-flex flex-column align-items-start mt-4 ">
          <img src={element2png} alt="" className="ani-left-right mb-2 pl-2" />
          <a
            className="btn btn-primary wow fadeInLeft learnmore fw-semibold"
            data-wow-delay="0.50s"
            data-wow-duration="1.2s"
            href="#"
            onClick={() => handleonclickGA()}
          >
            EXPLORE MORE <i className="icon ion-ios-arrow-thin-right"></i>
          </a>
        </div>
      </div>
      <div className='col-md-6 p-0 d-flex align-item-center justify-content-center spacecargoourservices wow slideInRight' data-wow-delay="0.6s" data-wow-duration="1.4s" style={{ backgroundImage: `url(${image1})` }}>
        {/* <img src={image1} className="wow fadeInLeft object-fit-cover mh-50" /> */}
        <div className="transperentbgblue w-100 h-100 ">
          <div className="d-flex justify-content-center flex-column h-100">
            <div className="d-flex align-items-center justify-content-center mb-3">
              <img src={pluselement} className="wow rotateIn" data-wow-delay="0.6s" data-wow-duration="1.4s" />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InhouseTechnology