import React, { useEffect, useState } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "./img/biglogoservice.png";
import roundtxtpng from "./img/roundtxt.png";
import element1png from "./img/element1.png";
import element2png from "./img/element2.png";

import uparrowelement from "./img/uparrowelement.png";

import PrivacyBanner from "./img/privacybanner.jpg";

import mannairoundtxt from "./img/Mannai Circle 001@72x.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "./css/animate.min.css";
import "./css/ionicons.min.css";
import "./css/style.css";
import "./css/privacyPolicy.css";
import "./css/globarpartner.css";
import "./js/timeline";
import "./js/script";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ReactGA from "react-ga4";
import ComponentServices from "../Components/ComponentServices";

const PrivacyPolicy = () => {
  const [metatitle, setmetatitle] = useState("Privacy Policy | Mannai Travel");

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.privacypolicy && ev?.data?.privacypolicy?.title ? ev?.data?.privacypolicy?.title : "Privacy Policy | Mannai Travel");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.privacypolicy && ev?.data?.privacypolicy?.title ? ev?.data?.privacypolicy?.title : "Privacy Policy | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Privacy Policy | Mannai Travel" });
        console.error("Error fetching SEO data: ", err)
      })
  }, [])


  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div class="main-body theme-orange dark-horizontal privacypolicy overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title={metatitle} />
          <Header />

          <div class="slide-container">
            <div class="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={PrivacyBanner}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div class="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>

              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>

              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    PRIVACY POLICY
                  </h3>
                  <div className="leftarrowanimationelement">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            <div className="col-12 col-md-2 col-lg-2  bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5">

              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-5  justify-content-center align-items-center padmob">
              <div className="mx-4 testing">
                <h2
                  className="text-white termpart"
                  style={{ textAlign: "center" }}
                >
                  Mannai Travel
                </h2>
                <br />
                <h2
                  className="text-white  termpart"
                  style={{ textAlign: "center" }}
                >
                  Privacy Policy
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4  ">
              <h3 className="text-center text-white mt-3 mt-lg-5 mt-xl-5 mt-xxl-5  mt-md-5 align-items-center position-relative termupdate">
                UPDATED DATE : 10-10-23
              </h3>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="row mobborderbottom">
              <div className="col-12 col-lg-12  wow fadeInRight ">
                <div className="cdecontentcd py-5">
                  <h3 className="text-white">Effective Date: 10-10-23</h3>
                  <p className="text-white my-2 head2 ">
                    Welcome to Mannai Travel Qatar! This Privacy Policy outlines
                    how we collect, use, disclose, and protect your personal
                    information when you visit our website or use our services.
                    Please read this policy carefully to understand how we
                    handle your data.
                  </p>
                  <ol className="mt-3">
                    <li className="text-white abouttext mt-2">
                      Information We Collect:
                    </li>
                    <ol>
                      <li className="text-white my-2 head2">
                        Personal Information: We may collect personal
                        information when you provide it to us, such as when you
                        make a reservation, sign up for an account, or contact
                        us. This information may include your name, contact
                        details, payment information, and other details
                        necessary for your travel booking.
                      </li>

                      <li className="text-white my-2 head2 ">
                        Usage Data: We collect information about how you use our
                        website and services, including your IP address, device
                        information, browser type, and pages visited. This
                        information helps us enhance your user experience and
                        improve our services.
                      </li>
                      <li className="text-white my-2 head2">
                        Cookies and Similar Technologies: Mannai Travel Qatar
                        uses cookies and similar technologies to collect data
                        about your browsing behavior and preferences. You can
                        manage your cookie preferences by adjusting your browser
                        settings.
                      </li>
                    </ol>
                    <li className="text-white  abouttext mt-2">
                      How We Use Your Information:
                    </li>
                    <ol>
                      <li className="text-white my-2 head2">
                        Booking and Reservations: We use your personal
                        information to process bookings and reservations,
                        including payment processing and communication regarding
                        your travel arrangements.
                      </li>
                      <li className="text-white  my-2 head2">
                        Customer Support: Your data may be used to provide
                        customer support and respond to inquiries, feedback, or
                        complaints.
                      </li>
                      <li className="text-white my-2 head2">
                        Personalization: We use your information to personalize
                        your experience on our website and suggest relevant
                        travel options.
                      </li>
                      <li className="text-white my-2 head2">
                        Marketing: With your consent, we may send you
                        promotional offers, newsletters, and updates about our
                        services.
                      </li>
                      <li className="text-white my-2 head2">
                        Analytics: We analyze user data to understand website
                        usage trends, assess the effectiveness of our marketing
                        efforts, and improve our services.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Content and Intellectual Property
                    </li>
                    <span className="my-2">
                      We may share your information with:
                    </span>
                    <ol>
                      <li className="text-white my-2 head2">
                        Service Providers: Third-party service providers who
                        assist us in delivering our services, including payment
                        processors and data hosting companies
                      </li>
                      <li className="text-white my-2 head2">
                        Legal Requirements: When required by law, we may
                        disclose your information to comply with a legal
                        obligation or protect our rights, privacy, safety, or
                        property.
                      </li>
                      <li className="text-white my-2 head2">
                        Business Transfers: In the event of a merger,
                        acquisition, or sale of all or part of our company, your
                        data may be transferred as part of the transaction.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      {" "}
                      Data Security:
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2 head2">
                        We implement appropriate security measures to protect
                        your personal information. However, no online platform
                        is completely secure, so we cannot guarantee the
                        absolute security of your data.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">
                      Your Privacy Choices:
                    </li>
                    <ol>
                      <li className="text-white my-2 head2">
                        Access and Correction: You can access and update your
                        personal information in your account settings.
                      </li>
                      <li className="text-white my-2 head2">
                        Marketing Communications: You can opt out of receiving
                        marketing communications by following the unsubscribe
                        instructions in our emails.
                      </li>
                      <li className="text-white my-2 head2">
                        Cookies: You can manage your cookie preferences through
                        your browser settings.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Changes to This Privacy Policy:
                    </li>
                    <ul className="list-unstyled">
                      <li className="text-white  my-2 head2">
                        We may update this Privacy Policy from time to time to
                        reflect changes in our practices and services. The
                        updated policy will be posted on our website with an
                        effective date.
                      </li>
                    </ul>
                    <li className="text-white abouttext mt-2">Contact Us:</li>
                    <ul className="list-unstyled">
                      <li className="text-white  my-2 head2">
                        If you have any questions, concerns, or requests
                        regarding your personal information or this Privacy
                        Policy, please contact us at
                        <a
                          className="text-decoration-none"
                          href="mailto: privacypolicy@mannai.com"
                        >
                          {" "}
                          privacypolicy@mannai.com.{" "}
                        </a>
                      </li>
                      <li className="text-white  my-2 head2">
                        Thank you for choosing Mannai Travel Qatar. Your privacy
                        is important to us, and we are committed to safeguarding
                        your personal information.
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
