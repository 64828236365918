import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";
// import $, { ready } from "jquery";
// import { ReactTitle } from "react-meta-tags";

// import WOW from "wow.js";

import leftbgimg from "../img/leftbg.png";
// import logopng from "../img/logo.png";
// import biglogopng from "../img/biglogobg.png";
import roundtxtpng from "../img/roundtxt.png";
import botlinepng from "../img/botline.png";
import verlinepng from "../img/verline.png";
import roundelementpng from "../img/roundelement.png";
// import elementbgpng from "../img/elementbg.png";
// import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
// import homevideo from "../img/homevideo.mp4";
import elementsmallxpng from "../img/elementsmallx.png";
// import atpng from "../img/airtravel.png";
// import rtpng from "../img/railticket.png";
// import mtpng from "../img/MeetGreet.png";
// import carcaroselpng from "../img/carrental.png";
// import homeicon from "../img/headerhome.png";
// import hotelpng from "../img/hotelaccomadation.png";
// import bgvideo from "../img/bg-video.jpg";
// by me
// import videoPause from "../img/pause1.png";
// import videoPlay from "../img/play1.png";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "socicon/css/socicon.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/Responsiveall.css";
import "../js/script";
import ReactGA from "react-ga4";
// import imgg from "../img/bg-horizontal4.jpg"

import RouteServices from "../../Routes/RouteServices";

const Banner3 = ({ b3dat }) => {

    const [bgimage, setbgimage] = useState(null);

    useEffect(() => {

        const [tablename, date, file] = b3dat?.imagePath.split('/');

        RouteServices
            .getImage(tablename, date, file)
            .then((res) => setbgimage(res.data))
            .catch((err) => console.error("Error while fetching Background Image: ", err));

    }, []);

    const handleonclickGA = () => {
        ReactGA.event({
            category: "Page Redirection",
            action: "Click",
            label: b3dat?.buttonlabel,
            // label: "your label", // optional
            value: b3dat?.buttonlink, // optional, must be a number
            // nonInteraction: true, // optional, true/false
            // transport: "xhr", // optional, beacon/xhr/image
          });
    }


    return (
        <div class="section pp-scrollable slide slide4 slide-dark">
            <div className="slide-container">
                <div className="slide-bg">
                    {/* <div className="inside" style={{backgroundImage: `url(${imgg})`}}></div> */}
                    {bgimage && <div className="inside" style={{ backgroundImage: `url(${URL.createObjectURL(bgimage)})` }}></div>}
                </div>
                <div className="container">
                    <div className="botline">
                        {" "}
                        <img src={botlinepng} alt="" />
                    </div>
                    <div className="verline">
                        {" "}
                        <img src={verlinepng} alt="" />
                    </div>
                    <div className="roundelement ">
                        {" "}
                        <img src={roundelementpng} alt="" />
                    </div>

                    <div className="element1botWWA">
                        {" "}
                        <img
                            src={elementsmallxpng}
                            className="wow pulse"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                        />
                    </div>
                    <div className="roundanimation">
                        <div className="roundtxtanimationplus">
                            {" "}
                            <img src={roundtxtpng} alt="" />
                        </div>
                        <div className="roundtextanimation">
                            {" "}
                            <img src={mannairoundtxt} alt="" />{" "}
                        </div>
                    </div>

                    <div className="contentpartwwa ">
                        <div
                            className="wow slideInLeft NormalHeading"
                            data-wow-duration="1.4s"
                        >
                            {/* WHO WE ARE */}
                            {b3dat?.heading}
                        </div>
                        {/* <div
                            className=" wow slideInRight SubHeading"
                            data-wow-delay=".2s"
                            data-wow-duration=".95s"
                        >
                            Built upon a foundation of core human values, Mannai
                            Travel stands as a cherished and esteemed company in
                            Qatar. Serving a diverse spectrum of customers, we are
                            renowned for our unwavering ethics and human values.
                            <br />
                            Our relentless dedication is focused on crafting an
                            exceptional organization within Qatar.
                        </div>{" "} */}
                        <div
                            className=" wow slideInRight SubHeading"
                            data-wow-delay=".2s"
                            data-wow-duration=".95s"
                        >
                            {b3dat?.title}
                        </div>
                        <div
                            className=" wow fadeInRight Pyara"
                            data-wow-delay="1.0s"
                            data-wow-duration="1.4s"
                        >
                            {b3dat?.subtitle}
                            <br />
                        </div>{" "}
                        <div>
                            <div className="mb-1">
                                {" "}
                                <img
                                    src={element2png}
                                    alt=""
                                    className="ani-left-right"
                                />
                            </div>
                            <Link
                                // to="/Whoweare"
                                to={b3dat?.buttonlink}
                                className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                                data-wow-delay="1.2s"
                                data-wow-duration="1.6s"
                                onClick={() => handleonclickGA()}
                            >
                                {/* ABOUT US{" "} */}
                                {b3dat?.buttonlabel}
                                <i className="icon ion-ios-arrow-thin-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="wow fadeInLeft animated leftbg"
                        data-wow-duration="2000ms"
                        data-wow-delay="0ms"
                        style={{
                            visibility: "visible",
                            animationDuration: "2000ms",
                            animationDelay: "0ms",
                            animationName: "fadeInLeft",
                        }}
                    >
                        <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner3