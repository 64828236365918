import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";
// import $, { ready } from "jquery";
// import { ReactTitle } from "react-meta-tags";

// import WOW from "wow.js";

import leftbgimg from "../img/leftbg.png";
// import logopng from "../img/logo.png";
// import biglogopng from "../img/biglogobg.png";
import roundtxtpng from "../img/roundtxt.png";
import botlinepng from "../img/botline.png";
import verlinepng from "../img/verline.png";
import roundelementpng from "../img/roundelement.png";
// import elementbgpng from "../img/elementbg.png";
// import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
// import homevideo from "../img/homevideo.mp4";
// import elementsmallxpng from "../img/elementsmallx.png";
// import atpng from "../img/airtravel.png";
// import rtpng from "../img/railticket.png";
// import mtpng from "../img/MeetGreet.png";
// import carcaroselpng from "../img/carrental.png";
// import homeicon from "../img/headerhome.png";
// import hotelpng from "../img/hotelaccomadation.png";
// import bgvideo from "../img/bg-video.jpg";
// by me
import videoPause from "../img/pause1.png";
import videoPlay from "../img/play1.png";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "socicon/css/socicon.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/Responsiveall.css";
import "../js/script";
import ReactGA from "react-ga4";

import RouteServices from "../../Routes/RouteServices";

const Banner1 = ({ b1dat }) => {
  const [playvideo, setPlayvideo] = useState(true);
  const [bannervid, setbannervid] = useState(null);

  useEffect(() => {
    const [tablename, date, file] = b1dat?.videoPath.split('/');

    RouteServices
      .getImage(tablename, date, file)
      .then((res) => {
        setbannervid(res.data);

        //required to autoplay videos if you are rendering it dynamically from backend
        setTimeout(() => {
          const video = document.getElementById("myMovie");
          if (video?.paused) {
            video?.play();
            setPlayvideo(true);
          }
        }, 1);
        // const video = document.getElementById("myMovie");
        // if (video.paused) {
        //   video.play();
        //   setPlayvideo(true);
        // }
      })
      .catch((err) => console.error("Error fetching Banner Video: ", err));

  }, [])


  // useEffect(() => {
  //   const ppnav = document.getElementById("pp-nav");
  //   const tooltip = document.querySelector(".pp-tooltip");
  //   if (ppnav) {
  //     ppnav.classList.remove("d-none");
  //     ppnav.classList.add("d-block");
  //   }

  //   if (ppnav) {
  //     ppnav.remove(); // Remove the ppnav element from the DOM
  //   }

  //   // setTimeout(() => {
  //   //   const video = document.getElementById("myMovie");
  //   //   if (video.paused) {
  //   //     video.play();
  //   //     setPlayvideo(true);
  //   //   }
  //   // }, 100);


  //   // const video = document.getElementById("myMovie");
  //   // if (video.paused) {
  //   //   video.play();
  //   //   setPlayvideo(true);
  //   // }
  // }, []);

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: b1dat?.buttonlabel,
      // label: "your label", // optional
      value: b1dat?.buttonlink, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      // transport: "xhr", // optional, beacon/xhr/image
    });
  }

  return (
    <div class="section pp-scrollable slide slide1 slide-dark">
      <div
        className="slide-container"
        style={{ margin: 0, padding: 0, height: "100vh" }}
      >
        <div className="container" style={{ zIndex: 400 }}>
          <div className="contentparthome">
            <div
              className="wow slideInLeft  NormalHeading"
              data-wow-delay="2.0s"
              data-wow-duration="2.5s"
            >
              {/* WELCOME TO THE WORLD OF TRAVEL{" "} */}
              {b1dat?.heading}
            </div>
            <div
              className="wow fadeInRight   SubHeading"
              data-wow-delay="2.0s"
              data-wow-duration="2.6s"
            >
              {/* Enhancing Sustainable Travel Experiences Through
            Innovative Approaches and Tech Advancements! */}
              {b1dat?.title}
            </div>
            <div
              className="wow fadeInLeft  Pyara"
              data-wow-delay="2.0s"
              data-wow-duration="2.7s"
            >
              {/* Achieving Travel Program Excellence with a Preferred
            Global Partner{" "} */}
              {b1dat?.subtitle}
            </div>
            <div>
              <div className="mb-1">
                {" "}
                <img
                  src={element2png}
                  alt=""
                  className="ani-left-right"
                />
              </div>
              <Link
                to={b1dat?.buttonlink}
                className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                data-wow-delay="1.2s"
                data-wow-duration="1.6s"
                onClick={() => handleonclickGA()}
              >
                {/* CONTACT US{" "} */}
                {b1dat?.buttonlabel}
                <i className="icon ion-ios-arrow-thin-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="wow fadeInLeft animated leftbg"
          data-wow-duration="2000ms"
          data-wow-delay="0ms"
          style={{
            visibility: "visible",
            animationDuration: "2000ms",
            animationDelay: "0ms",
            animationName: "fadeInLeft",
          }}
        >
          {" "}
          <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />{" "}
        </div>
        <div className={"playpause"}>
          {/* <ion-icon name="pause-outline"></ion-icon> */}

          <i
            onClick={() => {
              const video = document.getElementById("myMovie");
              if (video?.paused) {
                video?.play();
                setPlayvideo(true);
                ReactGA.event({
                  category: "Video Playback",
                  action: "Click",
                  label: "Play Video",
                  value: "Video Played"
                });
              } else {
                video?.pause();
                setPlayvideo(false);
                ReactGA.event({
                  category: "Video Playback",
                  action: "Click",
                  label: "Pause Video",
                  value: "Video Paused"
                });
              }

              // const button = document.querySelector(".playpause");
              // button.remove();
            }}
          >
            {" "}
            {playvideo ? (
              <img
                src={videoPause}
                width="40"
                height="40"
                alt="pause"
              // className="pausevid"
              />
            ) : (
              <img
                src={videoPlay}
                width="40"
                height="40"
                alt="pause"
              // className="playvid"
              />
            )}{" "}
          </i>
        </div>

        <div className="botline">
          {" "}
          <img src={botlinepng} alt="" />
        </div>
        <div className="verline">
          {" "}
          <img src={verlinepng} alt="" />
        </div>
        <div className="roundelement ">
          {" "}
          <img src={roundelementpng} alt="" />
        </div>
        {/* <div className="element1 float-bob-y">
        {" "}
        <img src={element1png} alt="" />
      </div>
      */}
        {/* <div
        className="elementbg"
        style={{
          visibility: "visible",
          animationDuration: "2000ms",
          animationDelay: "0ms",
          animationName: "fadeInRight",
        }}
      >
        {" "}
        <img src={elementbgpng} alt="" />
      </div> */}

        <div className="roundanimation">
          <div className="roundtxtanimationplus">
            {" "}
            <img src={roundtxtpng} alt="" />
          </div>
          <div className="roundtextanimation">
            {" "}
            <img src={mannairoundtxt} alt="" />{" "}
          </div>
        </div>
        {bannervid ?
          <video
            id="myMovie"
            width="100%"
            height="auto"
            preload="auto"
            muted
            loop
            autoplay
          >
            <source src={URL.createObjectURL(bannervid)} type="video/mp4" />
          </video>
          : ""}

      </div>
    </div>
  )
}

export default Banner1