import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/visitthailand.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import BlogNewsServices from "./BlogsandNewsServices/BlogNewsServices";
import moment from "moment";
import ReactGA from "react-ga4";
import ComponentServices from "../../Components/ComponentServices";

const News = () => {
  const params = useParams();
  const [metatitle, setmetatitle] = useState("Blogs & News | Mannai Travel");
  const [visiblenews, setvisiblenews] = useState(4);
  const [news, setnews] = useState("");
  const [newsimage, setnewsimage] = useState('');
  const [newssubimgs, setnewssubimgs] = useState([]);
  const [simnewsimgs, setsimnewsimgs] = useState([]);

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.blogsandnews && ev?.data?.blogsandnews?.title ? ev?.data?.blogsandnews?.title : "Blogs & News | Mannai Travel")
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.blogsandnews && ev?.data?.blogsandnews?.title ? ev?.data?.blogsandnews?.title : "Blogs & News | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blogs & News | Mannai Travel" });
        console.error("Error fetching SEO data: ", err);
      });
  }, []);

  useEffect(() => {
    BlogNewsServices
      .getReadNews(params.ID)
      .then(async (e) => {
        setnews(e?.data);
        const [newstable, newsdate, newsfile] = e?.data?.imagePath.split('/');

        BlogNewsServices
          .getImage(newstable, newsdate, newsfile)
          .then((ev) => setnewsimage(URL.createObjectURL(ev?.data)))
          .catch((err) => console.error("Error fetching News Image: ", err));

        const newssubimg = e?.data?.newssubsection.map(async (pet) => {
          const [newssubtablename, newssubdate, newssubfile] = pet?.imagePath.split('/');

          const res = await BlogNewsServices.getImage(newssubtablename, newssubdate, newssubfile);
          return URL.createObjectURL(res.data);
        });

        const newssubfin = await Promise.all(newssubimg);
        setnewssubimgs(newssubfin);

        const simnewsimgsda = e?.data?.similarnews.map(async (gun) => {
          const [simnewstable, simnewsdate, simnewsfile] = gun?.imagePath.split('/');

          const res = await BlogNewsServices.getImage(simnewstable, simnewsdate, simnewsfile);
          return URL.createObjectURL(res.data);
        });

        const simnewsfin = await Promise.all(simnewsimgsda);

        setsimnewsimgs(simnewsfin);
      })
  }, [params]);


  const handleExploreMore = () => {
    setvisiblenews((prev) => prev + 4);
    ReactGA.event({
      category: "Show More Content",
      action: "Click",
      label: "EXPLORE MORE",
      value: "EXPLORE MORE",
    });
  };

  const handleExploreLess = () => {
    setvisiblenews(4);
    ReactGA.event({
      category: "Show Less Content",
      action: "Click",
      label: "EXPLORE LESS",
      value: "EXPLORE LESS",
    });
  };

  const isExploreLessVisible = visiblenews >= news?.similarnews?.length;

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {

    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, [params.ID]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handleonclickGA = (des) => {
    if (des === "READ NEWS") {
      handleScrollToTop()
    }
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: des,
      value: des,
    });
  }

  return (
    <div class="main-body theme-orange dark-horizontal visithailand overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title={metatitle} />
          <Header />
          <div class="slide-container">
            {/* for banner  */}
            <div class="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={newsimage}
                  alt=""
                  height="100"
                  width="100"
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div class="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>

              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>
              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>
              {/* banner text  */}
              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <p
                    class="wow slideInLeft text-white fw-bold fs-1 visthailandbannerfont"
                    data-wow-duration="1.2s"
                  >
                    {`News-${news?.id}`}
                  </p>
                  <p
                    class="normaltext  wow slideInRight fw-bold visthailandnormalfont"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {news?.title}
                  </p>
                  <div>
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row bgthailandblue p-4">
            {/* 5*6 int img with play png  */}
            <div className="col-12 col-md-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5">
                <img
                  src={playthailand}
                  height="80"
                  width="80"
                  alt=""
                  class="wow pulse  "
                ></img>
              </div>
            </div>

            <div className="col-12 col-md-5 d-flex justify-content-center align-items-center padmob">
              <h3
                className="text-white text-align: center"
                style={{ textAlign: "center" }}
              >
                STAY UPDATED WITH THE LATEST NEWS
              </h3>
            </div>
            <div className="col-12 col-md-5">
              <div className="d-flex justify-content-center">
                <img src={camthailand} alt="" />
                <h5 className="mt-3 mb-0 ms-2 text-white author">
                  Author Name
                </h5>
              </div>
              <h5 className="text-center text-white mt-3 manasi">
                {news?.authorname}
              </h5>

              <div className="d-flex justify-content-center mt-3 ms-3">
                <ul className="footersocial footer social-rounded d-flex">
                  <li>
                    <a href="https://www.facebook.com/mannaitravelqatar" onClick={() => handleonclickGA("Facebook")}>
                      <i className="socicon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mannaitravelqatar/" onClick={() => handleonclickGA("Instagram")}>
                      <i className="socicon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MannaiTravel" onClick={() => handleonclickGA("Twitter")}>
                      <i className="socicon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/83501210/admin/feed/posts/" onClick={() => handleonclickGA("LinkedIn")}>
                      <i className="socicon-linkedin"></i>
                    </a>
                  </li>
                  <img
                    height="25"
                    className="mt-2"
                    src={sharethailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white share">Share</p>
                </ul>
              </div>

              <div className="d-flex justify-content-around mt-2 gap-2">
                <div className="d-flex justify-content-center">
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img height="25" className="mt-2" src={penthailand} alt="" />
                  <p className="mt-2 mb-0 ms-2 text-white  text-center">
                    {`Posted: ${moment(news?.updatedDate).format("DD-MM-yyyy")}`}
                  </p>
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img
                    height="25"
                    className="mt-2"
                    src={compassthailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white text-center">
                    {`Read Time: ${news?.readtime}`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="py-4 px-5">
              <h2 className="text-white mobcenterhead">{news?.subtitle}</h2>
              <p className="text-white abouttext mt-2 mobcenterpara">
                {news?.description}
              </p>
            </div>

            {/* PHI PHI SECTION  */}
            {news?.newssubsection?.map((e, i) =>
              i === 0 || i % 2 === 0 ? (
                <div className="row mobborderbottom" key={i}>
                  <div className="col-12 col-lg-3 MesgPicthailand wow fadeInLeft">
                    <img className="roundedtop  phiphi1" src={newssubimgs[i]} alt="NewsSubImage" />{" "}
                  </div>

                  <div className="col-12 col-lg-9 d-flex  flex-column align-items-center position-relative wow fadeInRight ">
                    <div className="p-5">
                      <h2 className="text-white w-100 mobcenterhead ">
                        {e?.title}
                      </h2>
                      <p className="text-white abouttext mt-2">{e?.description}</p>
                      <img
                        className="position-absolute editplusnew"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mobborderbottom">
                  <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative ">
                    <div className="p-5">
                      <h2 className="text-white text-end w-100 mobcenterhead ">
                        {e?.title}
                      </h2>
                      <p className="text-white abouttext text-end mt-2">
                        {e?.description}
                      </p>
                      <img
                        className="position-absolute editplusnewright"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 MesgPicthailand ">
                    <img className="roundedtop phiphi3" src={newssubimgs[i]} alt="" />{" "}
                  </div>
                </div>
              )
            )}

            <div className="ms-5 py-5">
            
            </div>
          </div>

          <div className="bgthailandblue px-5 py-4">
            <h3 className="text-white">FAQ'S</h3>
          </div>

          <div className="linearthailandbgm pt-4">
            {/* accordion  */}
            <div className="container">
              <div className="row">
                {/* <!-- start  --> */}
                {news?.faqs?.map((e, i) => (
                  <div className="col-lg-6 col-12 p-lg-4 py-2 px-3  " key={i}>
                    <div className="accordion" id={`#accordionExample${i}`}>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header m-3"
                          id={`headingOne${i}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${i}`}
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            {e?.question}
                          </button>
                        </h2>
                        <div
                          id={`collapseOne${i}`}
                          className="accordion-collapse collapse "
                          aria-labelledby={`headingOne${i}`}
                          data-bs-parent={`#accordionExample${i}`}
                        >
                          <div className="accordion-body">
                            <p>{e?.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Blog list section  */}
            <div className="row py-4 px-5">
              <div className="col-12 col-lg-3 bgsquarex d-flex justify-content-center align-items-center h-150">
                <h2 className="text-white m-0">SIMILAR NEWS</h2>
              </div>
            </div>

            <div class="container-fluid row">
              {news?.similarnews?.slice(0, visiblenews)?.reverse().map((e, i) => (
                <div class="col-12 col-lg-6 " key={i}>
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      {e?.id}
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      {e?.imageheader}
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={simnewsimgs[i]} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn editbottom wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      <Link to={`/Blogsandnews/News/${e?.id}`}>
                        <a
                          class="btn btn-primary mr-4 fr"
                          href="#"
                          onClick={() => handleonclickGA("READ NEWS")}
                        >
                          READ NEWS{" "}
                          <i class="icon ion-ios-arrow-thin-right"></i>
                        </a>{" "}
                      </Link>
                    </div>
                    <div class="bolgimgtitle ">{e?.subtitle}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center">
              {isExploreLessVisible ? (
                <button
                  className="btn btn-primary mr-4 mb-3 explorelap"
                  onClick={handleExploreLess}
                >
                  EXPLORE LESS{" "}
                  <i className="icon ion-ios-arrow-thin-left"></i>
                </button>
              ) : (
                <button
                  className="btn btn-primary mr-4 mb-3 explorelap"
                  onClick={handleExploreMore}
                >
                  EXPLORE MORE{" "}
                  <i className="icon ion-ios-arrow-thin-right"></i>
                </button>
              )}
            </div>

          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default News;
